import React from 'react';
import { BaseSelect } from './baseSelect';

export const Checkbox = ({
  campaignID,
  componentID,
  componentProps,
  setComponentProps,
  setComponentId,
  questions,
  setQuestions,
  setContentSideBar,
  optionID,
  setOptionID
}) => {
  return (
    <BaseSelect
      props={{
        campaignID,
        componentID,
        componentProps,
        setComponentProps,
        setComponentId,
        questions,
        setQuestions,
        setContentSideBar,
        optionID,
        setOptionID
      }}
      title="Múltipla Escolha"
      type="CHECKBOX"
      icon="checkmark"
    />
  );
};
