import React from 'react';
import ReactFlow, {
  Controls,
  FlowElement,
  ReactFlowProvider
} from 'react-flow-renderer';
import './index.scss';

export default function Flow(props) {
  const renderLabel = (item, index) => {
    const truncateStr = (str, n) => {
      return str.length > n ? str.substr(0, n - 1) + '...' : str;
    };
    return (
      <div>
        {index === 0 && <div className="labelType">Boas vindas</div>}
        {index !== 0 && (
          <div className="node">
            <div className="labelIndex">{index}</div>
            <div className="labelType">{truncateStr(item.texto, 100)}</div>
          </div>
        )}
      </div>
    );
  };

  const fetchTreeElements = () => {
    const elements: FlowElement[] = [];
    const positions: { id: string; x: number; y: number }[] = [];
    let x = 150;
    let y = 25;

    props.data.forEach((item, index) => {
      const element: FlowElement[] = [];

      if (item.opcoes && item.opcoes.length && item.opcoes[0]) {
        item.opcoes.forEach((innerItem, index) => {
          if (!innerItem.proxima_pergunta_id && !item.proxima_pergunta_id) {
            return;
          }
          positions.push({
            id: innerItem.proxima_pergunta_id
              ? innerItem.proxima_pergunta_id
              : item.proxima_pergunta_id,
            x: (600 / item.opcoes.length) * index,
            y: y + 100
          });
          element.push({
            style: { opacity: 1 },
            id: `${item.id}-${innerItem.proxima_pergunta_id}-${index}`,
            source: item.id,
            target: innerItem.proxima_pergunta_id
              ? innerItem.proxima_pergunta_id
              : item.proxima_pergunta_id,
            label: innerItem.proxima_pergunta_id ? innerItem.valor : null
          });
        });
      } else {
        x = 100;
        positions.push({ id: item.id, x, y });
        item.proxima_pergunta_id &&
          element.push({
            style: { opacity: 1 },
            id: `${item.id}-${item.proxima_pergunta_id}`,
            source: item.id,
            target: item.proxima_pergunta_id
          });
      }

      const position = positions.find(el => el.id === item.id) || { x, y };
      element.push({
        id: item.id,
        data: {
          label: renderLabel(item, index)
        },
        position: { x: position.x, y: position.y },
        style: { width: 400, opacity: 1 }
      });

      y += 100;
      elements.push(...element);
    });

    return elements;
  };

  /* const [treeElements, setTreeElements] = useState(fetchTreeElements());

  useEffect(() => {
    setTreeElements(fetchTreeElements());
  }, [props.data, fetchTreeElements]); */

  return (
    <ReactFlowProvider>
      <ReactFlow elements={fetchTreeElements()} defaultZoom={0.8}>
        <Controls />
      </ReactFlow>
    </ReactFlowProvider>
  );
}
