import { combineReducers, Reducer } from 'redux';

import { Actions, State } from './type';

// Add reducers
import { reducer as user } from './user';
import { reducer as campaign } from './campaign';

// End add reducers

export const reducer: Reducer<State, Actions> = combineReducers({
  user,
  campaign
});
