import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';

export interface CampanhaDetalhe {
  id: number;
  campanha_id: number;
  questionario: Record<string, unknown>;
  css: Record<string, unknown>;
  tipo: string;
  percentual_apresentacao: number;
  versao: number;
  caminho_html: string;
  orcamento: number;
}

export class CampanhaDetalheService {
  private static resource = '/campanhas/detalhes';

  public async buscarCampanhaDetalhePorId(
    idCampanhaDetalhe: number
  ): Promise<AxiosResponse<CampanhaDetalhe>> {
    return HttpClient.get(
      `${CampanhaDetalheService.resource}/por-id/${idCampanhaDetalhe}`
    );
  }
}
