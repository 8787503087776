import './campanha-status.scss';
import React from 'react';
import classNames from 'classnames';
import { Icon } from 'morpheus-base-react';

function getDescricao(status) {
  switch (status) {
    case 'ATIVO':
      return 'Ativa';
    case 'PAUSADO':
      return 'Pausada';
    case 'INATIVO':
      return 'Inativa';
    default:
      return status;
  }
}

function getIcon(status) {
  switch (status) {
    case 'ATIVO':
      return (
        <Icon
          viewIcone="CheckCircle"
          iconeClass="fill-greenish-teal campanha-status__icon"
        />
      );
    case 'PAUSADO':
      return (
        <Icon
          viewIcone="ExclamationTriangle"
          iconeClass="fill-tangerine campanha-status__icon"
        />
      );
    case 'INATIVO':
      return (
        <Icon
          viewIcone="ExclamationOctagon"
          iconeClass="fill-neon-red campanha-status__icon"
        />
      );
    default:
      return;
  }
}

export function CampanhaStatus(props: { status: string }) {
  const classes = classNames(
    'campanha-status',
    `campanha-status--${props.status.toLowerCase()}`
  );
  return (
    <>
      <span className={classes}>
        <span className="campanha-status__icon-container">
          {getIcon(props.status)}
        </span>
        <span>{getDescricao(props.status)}</span>
      </span>
    </>
  );
}
