import React, { useEffect, useState } from 'react';
import './novo-formulario.scss';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteProps } from 'react-router-dom';
import { BasePage, StepperWizard } from 'morpheus-base-react';
import { clearCampaign } from 'app/store/campaign/action';

import {
  StepperLabel,
  StartingStep,
  SelectionStep,
  CustomizationStep,
  ConversationStep,
  TemplateStep
} from './components';
import { PublishingStep } from './components/publishing-step';

type Props = {
  campanhaId: number;
} & RouteProps;

const NovoFormularioComp = ({ campanhaId, history }: Props) => {
  const campaignData = useSelector((state: any) => state.campaign.campaign);
  const [currentStep, setCurrentStep] = useState(campanhaId ? 1 : 0);
  const dispatch = useDispatch();

  const selectUser = useSelector(state => state.user);

  useEffect(() => {
    if (!campanhaId) {
      dispatch(clearCampaign());
      return;
    }
  }, [campanhaId, dispatch]);

  useEffect(() => {
    sessionStorage.setItem('currentStep', currentStep.toString());
  }, [currentStep]);

  const steps = [
    {
      stepTitle: 'Introdução',
      stepDescrip: 'Iniciar a criação de um novo formulário.'
    },
    {
      stepTitle: 'Dados Básicos',
      stepDescrip: 'Nome e Layout do seu formulário'
    },
    {
      stepTitle: 'Customizar Chat',
      stepDescrip: 'Detalhes de apresentação visual do seu chat.'
    },
    {
      stepTitle: 'Página Inicial',
      stepDescrip:
        'Customização da páginal inicial do questionário (caso seja do tipo template).'
    },
    {
      stepTitle: 'Roteiro',
      stepDescrip: 'Crie o fluxo de conversação do formulário'
    },
    {
      stepTitle: 'Finalizar',
      titleWizard: 'Título - Step 5 - Finalizar'
    }
  ];

  const wizard = [
    {
      cleanSlate: true,
      wizardContent: (
        <StartingStep
          forwardAction={() => setCurrentStep(1)}
          campanhaId={campanhaId}
        />
      )
    },
    {
      stepTitle: 'Nome do formulário',
      show: true,
      cleanSlate: true,
      wizardContent: (
        <SelectionStep
          forwardAction={() => setCurrentStep(2)}
          backwardsAction={() => setCurrentStep(0)}
        />
      )
    },
    {
      cleanSlate: true,
      wizardContent: (
        <CustomizationStep
          forwardAction={() => {
            campaignData.tipo !== 'PAGINA_INTEIRA'
              ? setCurrentStep(3)
              : setCurrentStep(4);
          }}
          backwardsAction={() => setCurrentStep(1)}
        />
      )
    },
    {
      cleanSlate: true,
      titleWizard: 'Templatess',
      wizardContent: (
        <TemplateStep
          forwardAction={() => setCurrentStep(4)}
          backwardsAction={() => setCurrentStep(2)}
        />
      )
    },
    {
      cleanSlate: true,
      titleWizard: '',
      wizardContent: (
        <ConversationStep
          backwardsAction={() => {
            campaignData.tipo !== 'PAGINA_INTEIRA'
              ? setCurrentStep(3)
              : setCurrentStep(2);
          }}
          forwardAction={() => setCurrentStep(5)}
        />
      )
    },
    {
      cleanSlate: true,
      titleWizard: '',
      wizardContent: (
        <PublishingStep
          backwardsAction={() => setCurrentStep(4)}
          finishAction={() => history.push('/formularios')}
        />
      )
    }
  ];

  return (
    <BasePage margin="8px">
      <StepperWizard
        steps={steps}
        stepperColor="#2ecc71"
        wizard={wizard}
        withCircle
        color="#2ecc71"
        circleContent={
          <StepperLabel
            name={
              !selectUser || !selectUser.user ? '' : selectUser.user.firstName
            }
          />
        }
        controlledStep={wizard[currentStep]}
        currentStep={currentStep}
        disabledClick
      />
    </BasePage>
  );
};

export const NovoFormulario = withRouter(NovoFormularioComp);
