import React, { useEffect, useState } from 'react';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import './components.scss';

import { fetchIcon } from '../fetchIcon';
import { styled } from '@material-ui/styles';

import {
  CampaignQuestionService,
  Question
} from '../../../../../../service/campaign-pergunta-service';
import { TabMenu } from '../TabMenu';
import { Icon, TextField, Button } from 'morpheus-base-react';

const Title = styled('h1')({
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  color: '#adbcd8',
  marginLeft: '6px'
});

export const Image = ({
  campaignID,
  componentID,
  componentProps,
  setComponentProps,
  setComponentId,
  questions,
  setQuestions,
  setContentSideBar,
  optionID,
  setOptionID
}) => {
  const [currentlyActive, setCurrentlyActive] = useState(1);
  const [text, setText] = useState<string | ArrayBuffer | null>('');
  const [imgBase64, setImgBase64] = useState<string | ArrayBuffer | null>('');
  const [filename, setFilename] = useState<string | ArrayBuffer | null>('');
  const [editMode, setEditMode] = useState(false);
  const questionService = new CampaignQuestionService();

  useEffect(() => {
    if (componentProps) {
      setEditMode(true);
      setText(componentProps.texto);
    }
  }, [componentProps]);

  const getQuestion = () => {
    const question = {
      campanha_id: campaignID,
      opcoes: [null],
      texto: imgBase64 ? imgBase64 : text,
      formatting_settings: null,
      tipo: 'GIF',
      proxima_pergunta_id: null,
      final_fluxo: true,
      mensagem_inicial: false,
      variavel: null,
      mascara: null,
      variavel_integracao: null
    };
    return question;
  };

  const callback = (data: Question) => {
    const copyQuestions = [...questions];
    const newQuestion = data;
    const backQuestion = copyQuestions[componentID - 1];
    const remainingQuestions = backQuestion.proxima_pergunta_id;
    if (remainingQuestions) {
      newQuestion.final_fluxo = false;
      newQuestion.proxima_pergunta_id = remainingQuestions;
    }

    optionID > 0
      ? (backQuestion.opcoes[optionID - 1].proxima_pergunta_id = newQuestion.id)
      : (backQuestion.proxima_pergunta_id = newQuestion.id);
    newQuestion.opcoes = [null];
    backQuestion.final_fluxo = false;
    copyQuestions.push(newQuestion);
    setQuestions(copyQuestions);
  };

  const sendQuestion = () => {
    const question = getQuestion();
    questionService
      .createQuestion(question)
      .then(result => result.data && callback(result.data));
  };

  const saveQuestion = () => {
    if (!editMode) {
      sendQuestion();
      onClose();
      return;
    }

    const copyQuestions = [...questions];
    copyQuestions[componentID - 1].texto = text;
    setQuestions(copyQuestions);
    onBack();
  };

  const onBack = () => {
    setContentSideBar('DEFAULT');
    setComponentProps(null);
  };

  const onClose = () => {
    setComponentId(0);
    setOptionID(0);
  };

  const onClick = () =>
    currentlyActive === 1 ? setCurrentlyActive(2) : setCurrentlyActive(1);

  function chooseFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.gif,.jpg,.jpeg,.png';
    input.setAttribute('data-max-size', '10240');
    input.onchange = (e: any) => {
      if (e != null) {
        const file = e.target.files[0];
        setFilename(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setImgBase64(reader.result);

        reader.onerror = () => {
          console.error(reader.error);
          alert('Erro ao inserir arquivo, tente novamente');
        };
      }
    };
    input.click();
  }

  function onRemove() {
    setImgBase64('');
    setText('');
    setFilename('');
  }

  return (
    <div className="containerConversationStep">
      <div className="contentConversationStep">
        <div className="headerConversationStep">
          <button onClick={onBack}>{fetchIcon('arrowLeft')}</button>
          {fetchIcon('picture')}
          <Title>Imagem</Title>
        </div>
        <div>
          <TabMenu currentlyActive={currentlyActive} onClick={onClick} />
          {currentlyActive === 1 && (
            <div>
              <div className="containerFile">
                <div className="wrapperFile">
                  {imgBase64 === '' && (
                    <div className="file">
                      <div className="iconImport">
                        <Icon viewIcone="FileImport" />
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          fontFamily: 'Source Sans Pro',
                          color: '#62708b'
                        }}
                      >
                        Tamanho máximo da imagem: 1Mb
                      </p>
                      <a
                        className="inputImport"
                        style={{ fontFamily: 'Source Sans Pro' }}
                        onClick={chooseFile}
                      >
                        Enviar imagem
                      </a>
                    </div>
                  )}
                  {!!imgBase64 && (
                    <div className="imageContainer">
                      <img src={imgBase64} className="image" />
                      <Button
                        variant="text"
                        style={{
                          display: 'block',
                          margin: '10px auto 0 auto'
                        }}
                        onClick={onRemove}
                      >
                        Remover
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#62708b',
                    fontFamily: 'Source Sans Pro'
                  }}
                >
                  URL da Imagem
                </p>
                {}
                <TextField
                  label="Digite a URL da imagem"
                  variant="outlined"
                  value={text || filename}
                  style={{ width: '270px' }}
                  onChange={({ target: { value } }) => setText(value)}
                  disabled={filename ? true : false}
                />
              </div>
            </div>
          )}
          {currentlyActive === 2 && (
            <div>
              <ReactGiphySearchbox
                apiKey="JejaDSuQ2m9Ec4PFh3ws3xBneleCoA0W"
                searchPlaceholder="Pesquisar..."
                onSelect={gif => setText(gif.images.original.url)}
                gifListHeight="460px"
                poweredByGiphy={false}
                masonryConfig={[
                  { columns: 2, imageWidth: 110, gutter: 5 },
                  { mq: '700px', columns: 3, imageWidth: 120, gutter: 5 }
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <div className="div-action-buttons">
        <div className="mph-action-buttons">
          <Button
            className="cancel"
            style={{
              backgroundColor: '#ffffff',
              color: '#004de8',
              margin: '8px 16px'
            }}
            onClick={() => onBack()}
          >
            Cancelar
          </Button>
          <Button
            className="insert"
            style={{ margin: '8px 16px' }}
            onClick={() => saveQuestion()}
          >
            Inserir
          </Button>
        </div>
      </div>
    </div>
  );
};
