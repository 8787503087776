import React, { useContext, useEffect } from 'react';
import { BasePage } from 'morpheus-base-react';
import { AuthContext } from 'containers/auth-context';

import './logout.scss';
import { RouteProps, withRouter } from 'react-router';

const LogoutComp = ({ history }: RouteProps) => {
  const { setUser } = useContext(AuthContext);
  useEffect(() => {
    setUser(undefined);
    history.push('/');
  }, [history, setUser]);
  return (
    <BasePage margin="8px">
      <div className="logout">
        <p>Sua sessão expirou.</p>
        <p>Aguarde enquanto está sendo redirecionado para o login novamente.</p>
      </div>
    </BasePage>
  );
};

export const Logout = withRouter(LogoutComp);
