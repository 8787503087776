import React from 'react';
import { Button as BaseButton } from 'morpheus-base-react';
import ArrowLeft from 'svg-react-loader!assets/images/angle-left.svg';
import ArrowRight from 'svg-react-loader!assets/images/angle-right.svg';
import { styled } from '@material-ui/styles';

interface NavigationButtonsProps {
  cancelAction?: () => void;
  forwardAction?: () => void;
  backwardsAction?: () => void;
  backwardLabel?: string;
  forwardLabel?: string;
}

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%'
});

const Button = styled(BaseButton)({
  margin: '8px 16px !important',
  padding: '9px 16px 11px 24px !important',
  borderRadius: '100px !important',
  border: '1px solid #004de8 !important',
  fontSize: '16px !important',
  color: '#004de8 !important',
  '&:focus': {
    backgroundColor: '#004de8',
    color: '#fff'
  },
  '& span': {
    marginRight: '8px'
  }
});

export const NavigationButtons = ({
  cancelAction,
  forwardAction,
  backwardsAction,
  backwardLabel,
  forwardLabel
}: NavigationButtonsProps) => {
  if (!backwardLabel) {
    backwardLabel = 'Voltar';
  }
  if (!forwardLabel) {
    forwardLabel = 'Avançar';
  }
  return (
    <Wrapper>
      <div>
        {cancelAction && (
          <Button variant="text" onClick={() => cancelAction()}>
            Cancelar
          </Button>
        )}
      </div>
      <div className="mph-action-buttons">
        {backwardsAction && (
          <Button variant="outlined" onClick={() => backwardsAction()}>
            <span>
              <ArrowLeft />
            </span>
            {backwardLabel}
          </Button>
        )}
        {forwardAction && (
          <Button variant="outlined" onClick={() => forwardAction()}>
            <span>{forwardLabel}</span>
            <ArrowRight />
          </Button>
        )}
      </div>
    </Wrapper>
  );
};
