import React from 'react';
import { Select } from 'morpheus-base-react';

const fontSizeOptions = [
  {
    label: '12',
    value: 12
  },
  {
    label: '16',
    value: 16
  }
];

const fontFamilyOptions = [
  {
    label: 'Source Sans Pro',
    value: 'source sans pro'
  },
  {
    label: 'Sans Serif',
    value: 'sans-serif'
  },
  {
    label: 'Arial',
    value: 'Arial'
  }
];

interface Props {
  setFontFamily: () => void;
  setFontFamilyBodyChat: () => void;
  setFontSize: () => void;
  defaultFontSize: number;
  defaultFontFamily: string;
}

export const CustomText = ({
  setFontFamily,
  setFontFamilyBodyChat,
  defaultFontFamily = 'Arial',
  setFontSize,
  defaultFontSize = 16
}: Props) => {
  return (
    <div className="mph-custom-text-wrapper">
      <p className="mph-title">Escolha a fonte de todo o texto do chat.</p>
      <Select
        className="mph-select"
        defaultValue={defaultFontFamily}
        label="Fonte"
        variant="outlined"
        options={fontFamilyOptions}
        onChange={el => {
          setFontFamily(el.target.value);
          setFontFamilyBodyChat(el.target.value);
        }}
      />
      <Select
        className="mph-select"
        defaultValue={defaultFontSize}
        label="Tamanho"
        options={fontSizeOptions}
        onChange={el => {
          setFontSize(el.target.value);
        }}
        variant="outlined"
      />
    </div>
  );
};
