import React from 'react';
import { Avatar, Button } from 'morpheus-base-react';
import { styled } from '@material-ui/styles';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '25px 10%',
  flexGrow: 1
});

const Title = styled('h2')({
  fontSize: '48px',
  color: '#FFF',
  fontWeight: 600,
  margin: 0
});

const Description = styled('p')({
  fontSize: '24px',
  color: '#FFF',
  margin: 0,
  marginTop: '8px'
});

const MessageSection = styled('div')({
  display: 'grid',
  marginTop: '72px'
});

const LeadMessage = styled('div')({
  display: 'flex',
  justifySelf: 'start',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  backgroundColor: 'rgba(255, 255, 255, 0.24)',
  borderRadius: '0 8px 8px 8px',
  padding: '8px 16px',
  marginBottom: '32px'
});

const UserMessage = styled('div')({
  display: 'flex',
  justifySelf: 'end',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px 8px 0 8px',
  padding: '8px 16px'
});

const MessageContent = styled('p')({
  margin: '0 8px'
});

const InputSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  backgroundColor: '#fff',
  height: '72px',
  fontSize: '32px'
});

const InputText = styled('input')({
  border: 0,
  fontSize: '32px',
  color: '#62708b'
});

const SendButton = styled('div')({
  border: 0,
  backgroundColor: 'transparent'
});

export const ChatContent = ({
  color = '#004de8',
  avatar,
  // chatButton,
  fontSize = 16,
  fontFamily = 'sans-serif',
  fontFamilyBodyChat = 'source sans pro',
  textColor
}) => {
  return (
    <>
      <Wrapper style={{ backgroundColor: color }}>
        <Title style={{ fontFamily: fontFamilyBodyChat, color: textColor }}>
          Olá!
        </Title>
        <Description
          style={{ fontFamily: fontFamilyBodyChat, color: textColor }}
        >
          Que bom que você tem interesse em nosso produto, vou te fazer algumas
          perguntinhas ok? Vai ser rápido.
        </Description>
        <MessageSection>
          <LeadMessage>
            <Avatar src={avatar} size="32">
              Gump Lead
            </Avatar>
            <MessageContent style={{ fontSize, fontFamily, color: textColor }}>
              Qual o seu nome?
            </MessageContent>
          </LeadMessage>
          <UserMessage>
            <MessageContent style={{ color, fontSize, fontFamily }}>
              Jonnathan
            </MessageContent>
            <Avatar size="32">User Name</Avatar>
          </UserMessage>
        </MessageSection>
      </Wrapper>
      <InputSection>
        <InputText placeholder="Digite o texto aqui…" />
        <SendButton>
          {/* <img src={chatButton} /> */}
          <Button style={{ backgroundColor: color }}>Enviar</Button>
        </SendButton>
      </InputSection>
    </>
  );
};
