import * as moment from 'moment';
import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';

export interface Estatisticas {
  acessos: {
    quantidade: number;
    quantidade_periodo_total: number;
    variacao_percentual_do_periodo: number;
  };
  custo_por_lead: {
    no_periodo: number;
    no_periodo_total: number;
    variacao_percentual_do_periodo: number;
  };
  parciais: {
    percentual: number;
    percentual_periodo_total: number;
    quantidade: number;
    quantidade_periodo_total: number;
    variacao_percentual_do_periodo: number;
  };
  sucesso: {
    percentual: number;
    percentual_periodo_total: number;
    quantidade: number;
    quantidade_periodo_total: number;
    variacao_percentual_do_periodo: number;
  };
  tempo_medio_entre_respostas_em_ms: {
    no_periodo: moment.Duration;
    no_periodo_total: moment.Duration;
    variacao_percentual_do_periodo: number;
  };
}

export interface RespostasEscolhidas {
  chave_resposta: string;
  porcentagem_selecoes: number;
  quantidade_selecoes: number;
  valor_resposta: string;
}

export interface ContagensPergunta {
  tempo_medio_de_resposta: number;
  porcentagem_pessoas_nao_responderam: number;
  porcentagem_pessoas_responderam: number;
  quantidade_pessoas_nao_responderam: number;
  quantidade_pessoas_responderam: number;
  respostas: RespostasEscolhidas[];
  total: number;
}

export interface EstatisticasPergunta {
  estatisticas: ContagensPergunta;
  id: number;
  is_escolha_opcao: boolean;
  texto: string;
  tipo: string;
  variavel: string;
}

export interface DiaHistograma {
  acessos: number;
  data: string;
  finalizado: number;
  nao_iniciado: number;
  parcial: number;
}

export interface EstatisticasTodasPerguntas {
  responderam: number;
  nao_responderam: number;
}

export interface EstatisticasPerguntasResultado {
  por_pergunta: EstatisticasPergunta[];
  totais: EstatisticasTodasPerguntas;
}

export class CampanhaEstatisticasService {
  private static resource = '/campanhas/estatisticas';

  public async buscarEstatisticas(
    idCampanha: number,
    inicio: Date,
    fim: Date
  ): Promise<AxiosResponse<Estatisticas>> {
    return HttpClient.get(
      `${CampanhaEstatisticasService.resource}/${idCampanha}`,
      {
        params: {
          inicio,
          fim
        },
        transformResponse: [
          data => {
            const json = JSON.parse(data);
            if (json.tempo_medio_entre_respostas_em_ms) {
              json.tempo_medio_entre_respostas_em_ms.no_periodo = moment.duration(
                json.tempo_medio_entre_respostas_em_ms.no_periodo,
                'ms'
              );
              json.tempo_medio_entre_respostas_em_ms.no_periodo_total = moment.duration(
                json.tempo_medio_entre_respostas_em_ms.no_periodo_total,
                'ms'
              );
            }
            return json;
          }
        ]
      }
    );
  }

  public async buscarHistograma(
    idCampanha: number,
    inicio: Date,
    fim: Date
  ): Promise<AxiosResponse<DiaHistograma[]>> {
    return HttpClient.get(
      `${CampanhaEstatisticasService.resource}/${idCampanha}/histograma`,
      {
        params: {
          inicio,
          fim
        }
      }
    );
  }

  public async buscarCustoPorLead(
    idCampanha: number,
    duracao: moment.Duration
  ) {
    return HttpClient.get(
      `${CampanhaEstatisticasService.resource}/${idCampanha}/custo_por_lead`,
      {
        params: {
          duracao: duracao.toISOString()
        },
        transformResponse: [
          data => {
            const json = JSON.parse(data);
            // json.periodo = parseFloat(json.periodo);
            // json.total = parseFloat(json.total);
            return json;
          }
        ]
      }
    );
  }

  public async buscarPerguntas(
    idCampanha: number,
    inicio: Date,
    fim: Date
  ): Promise<AxiosResponse<EstatisticasPerguntasResultado>> {
    return HttpClient.get(
      `${CampanhaEstatisticasService.resource}/${idCampanha}/perguntas`,
      {
        params: {
          inicio,
          fim
        }
      }
    );
  }
}
