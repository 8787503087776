import React from 'react';
import './loading.scss';

export default function LoadingComponent() {
  return (
    <>
      <div className="loading">Carregando o conteúdo</div>
    </>
  );
}
