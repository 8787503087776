import React from 'react';
import { fetchIcon } from './fetchIcon';
import { styled } from '@material-ui/styles';

interface MenuItemProps {
  icon: string;
  title: string;
  disabled?: boolean;
  onClick: () => void;
}

const Wrapper = styled('button')({
  cursor: 'pointer',
  padding: '18px 16px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 0,
  backgroundColor: 'transparent',
  borderBottom: '1px solid #e0e4f2',
  transition: 'background-color 0.4s ease-in-out',
  '&:hover': {
    backgroundColor: '#e0e4f2'
  },
  '&:disabled': {
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const Content = styled('p')({
  margin: 0,
  marginLeft: '8px',
  fontFamily: 'Source Sans Pro'
});

const ComingSoonWrapper = styled('div')({
  backgroundColor: '#ff9300',
  borderRadius: '8px',
  fontFamily: 'Source Sans Pro',
  color: '#fff',
  '& p': {
    fontSize: '12px',
    margin: 0,
    padding: '4px 8px'
  }
});

const ComingSoon = () => (
  <ComingSoonWrapper>
    <p>Em breve</p>
  </ComingSoonWrapper>
);

export const MenuItem = ({ icon, title, disabled, onClick }: MenuItemProps) => {
  return (
    <Wrapper disabled={disabled} onClick={() => onClick()}>
      <ContentWrapper>
        {fetchIcon(icon)}
        <Content>{title}</Content>
      </ContentWrapper>
      {disabled && <ComingSoon />}
    </Wrapper>
  );
};
