import { Actions } from '../type';
import { User } from './type';

import { ActionTypes } from './action';

export interface UserState {
  user?: User;
  error?: Record<string, unknown>;
}

const initialState: Readonly<UserState> = {};

export function reducer(
  state: UserState = initialState,
  action: Actions
): UserState {
  switch (action.type) {
    case ActionTypes.GetUser:
      const { user } = action.payload;
      return { ...state, user };
    case ActionTypes.ErrorUser:
      const { error } = action.payload;
      return { ...state, error };
    case ActionTypes.UpdateUser:
      return { ...state };
    default:
      return state;
  }
}
