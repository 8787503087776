import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NovoFormulario } from './novo-formulario';
import { getCampaign } from 'app/store/campaign/action';

export const EditarFormulario = props => {
  const [campaignID] = useState(props.match.params.campanhaId);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Editar Formulário -> campaignID', campaignID);
    if (campaignID) dispatch(getCampaign(campaignID));
  }, [campaignID, dispatch]);

  return <NovoFormulario campanhaId={props.match.params.campanhaId} />;
};
