import React, { useEffect, useState } from 'react';
import {
  Grid,
  Drawer,
  Tooltip,
  IconButton,
  SnackbarServiceInstance,
  Button
} from 'morpheus-base-react';
import '../../novo-formulario.scss';
import { styled } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';

import { CampaignQuestionService } from 'service/campaign-pergunta-service';
import Flow from 'components/flow/index';

import { MenuItem } from './MenuItem';
import { ConversationList } from './ConversationList';
import { NavigationButtons } from '../NavigationButtons';
import { Message } from './components/message';
import { Text } from './components/text';
import { Select } from './components/select';
import { fetchIcon } from './fetchIcon';
import { Email } from './components/email';
import { Checkbox } from './components/checkbox';
import { Image } from './components/image';
import { updateCampaign } from 'app/store/campaign';

const createConversationComponent = (data: any) => {
  if (data.mensagem_inicial) {
    return {
      icon: 'smile',
      title: 'Boas Vindas',
      order: 0,
      content: (
        <p
          key={0}
          style={{
            margin: 0,
            color: '#20396a',
            fontSize: '14px',
            fontFamily: 'Source Sans Pro'
          }}
        >
          {data.texto}
        </p>
      )
    };
  } else {
    // eslint-disable-next-line max-depth
    switch (data.tipo) {
      case 'LABEL':
        return {
          icon: 'wink',
          title: 'Mensagem de texto',
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          content: (
            <p
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              {data.texto}
            </p>
          )
        };

      case 'INPUT_TEXT':
        return {
          icon: 'message',
          title: 'Pergunta de resposta aberta',
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          content: (
            <p
              key={data.num}
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              {data.texto}
            </p>
          )
        };

      case 'MAIL':
        return {
          icon: 'email',
          title: 'E-mail',
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          content: (
            <p
              key={data.num}
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              {data.texto}
            </p>
          )
        };

      case 'GIF':
        return {
          icon: 'picture',
          title: 'Imagem',
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          content: (
            <p
              key={data.num}
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              Imagem ou GIF
            </p>
          )
        };

      case 'CHECKBOX':
        return {
          icon: 'checkmark',
          title: 'Multipla escolha',
          options: data.opcoes,
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          type: 'checkbox',
          content: (
            <p
              key={data.num}
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              {data.texto}
            </p>
          )
        };

      case 'BUTTON':
      case 'RADIO':
      case 'SELECT':
        return {
          icon: 'radio',
          title: 'Seleção única',
          options: data.opcoes,
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          content: (
            <p
              key={data.num}
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              {data.texto}
            </p>
          )
        };

      case 'HTML':
        return {
          icon: 'wink',
          title: 'HTML teste',
          nextQuestion: data.proxima_pergunta_id,
          id: data.id,
          content: (
            <p
              key={data.num}
              style={{
                margin: 0,
                color: '#20396a',
                fontSize: '14px',
                fontFamily: 'Source Sans Pro'
              }}
            >
              Lorem ipsum dolor sit amet…
            </p>
          )
        };

      default:
        return;
    }
  }
};

const LeftSection = styled(Grid)({
  borderRight: '1px solid #eaedf6',
  height: 'calc(100% - 60px)',
  overflow: 'auto'
});

const RightSection = styled(Grid)({
  backgroundColor: '#f9fafc',
  height: 'calc(100% - 60px)'
});

const Title = styled('h1')({
  alignItems: 'center',
  backgroundColor: 'white',
  color: '#62708b',
  display: 'flex',
  fontSize: '24px',
  fontWeight: 600,
  fontFamily: 'Source Sans Pro',
  height: '38px',
  justifyContent: 'flex-start',
  margin: 0,
  padding: '10px 16px',
  '& path': {
    fill: '#62708b !important'
  }
});

const TreeSection = styled('div')({
  height: 'calc(100% - 66px)'
});

const drawerContent = (
  campaignID,
  componentId,
  setComponentId,
  content,
  setContent,
  questions,
  setQuestions,
  newComponentProperties,
  setComponentProps,
  optionID,
  setOptionID
) => {
  switch (content) {
    case 'text':
      return (
        <Text
          campaignID={campaignID}
          componentID={componentId}
          componentProps={newComponentProperties}
          setComponentProps={setComponentProps}
          setComponentId={setComponentId}
          questions={questions}
          setQuestions={setQuestions}
          setContentSideBar={setContent}
          optionID={optionID}
          setOptionID={setOptionID}
        />
      );

    case 'message':
      return (
        <Message
          campaignID={campaignID}
          componentID={componentId}
          componentProps={newComponentProperties}
          setComponentProps={setComponentProps}
          setComponentId={setComponentId}
          questions={questions}
          setQuestions={setQuestions}
          setContentSideBar={setContent}
          optionID={optionID}
          setOptionID={setOptionID}
        />
      );

    case 'email':
      return (
        <Email
          campaignID={campaignID}
          componentID={componentId}
          componentProps={newComponentProperties}
          setComponentProps={setComponentProps}
          setComponentId={setComponentId}
          questions={questions}
          setQuestions={setQuestions}
          setContentSideBar={setContent}
          optionID={optionID}
          setOptionID={setOptionID}
        />
      );

    case 'checkbox':
      return (
        <Checkbox
          campaignID={campaignID}
          componentID={componentId}
          componentProps={newComponentProperties}
          setComponentProps={setComponentProps}
          setComponentId={setComponentId}
          questions={questions}
          setQuestions={setQuestions}
          setContentSideBar={setContent}
          optionID={optionID}
          setOptionID={setOptionID}
        />
      );

    case 'button':
    case 'radio':
    case 'select':
      return (
        <Select
          campaignID={campaignID}
          componentID={componentId}
          componentProps={newComponentProperties}
          setComponentProps={setComponentProps}
          setComponentId={setComponentId}
          questions={questions}
          setQuestions={setQuestions}
          setContentSideBar={setContent}
          optionID={optionID}
          setOptionID={setOptionID}
          type={content.toUpperCase()}
        />
      );

    case 'image':
      return (
        <Image
          campaignID={campaignID}
          componentID={componentId}
          componentProps={newComponentProperties}
          setComponentProps={setComponentProps}
          setComponentId={setComponentId}
          questions={questions}
          setQuestions={setQuestions}
          setContentSideBar={setContent}
          optionID={optionID}
          setOptionID={setOptionID}
        />
      );

    case 'evaluation':
      return;

    default:
      return (
        <div className="containerComponents">
          <div className="headerComponents">
            <p>Componentes</p>
            <button onClick={() => setComponentId(0)}>
              Fechar {fetchIcon('close')}
            </button>
          </div>
          <MenuItem
            icon="chat"
            title="Mensagem de texto"
            disabled={false}
            onClick={() => setContent('text')}
          />
          <MenuItem
            icon="message"
            title="Pergunta aberta"
            disabled={false}
            onClick={() => setContent('message')}
          />
          <MenuItem
            icon="checkmark"
            title="Pergunta de múltipla escolha"
            disabled={false}
            onClick={() => setContent('checkbox')}
          />
          <MenuItem
            icon="radio"
            title="Seleção única"
            disabled={false}
            onClick={() => setContent('select')}
          />
          <MenuItem
            icon="picture"
            title="Imagem"
            disabled={false}
            onClick={() => setContent('image')}
          />
          <MenuItem
            icon="email"
            title="E-mail"
            disabled={true}
            onClick={() => setContent('email')}
          />
          <MenuItem
            icon="star"
            title="Avaliação"
            disabled={true}
            onClick={() => setContent('evaluation')}
          />
        </div>
      );
  }
};

const createCardList = questionsList => {
  return questionsList.map(questionItem => {
    return createConversationComponent(questionItem);
  });
};

export const ConversationStep = ({ backwardsAction, forwardAction }) => {
  const service = new CampaignQuestionService();
  const dispatch = useDispatch();
  const campaignData = useSelector((state: any) => state.campaign.campaign);

  const fetchQuestions = _get(campaignData, 'questionario', []);
  const [questions, setStateQuestions] = useState(fetchQuestions);

  const campaignID = _get(campaignData, 'campanha_id', 0);

  useEffect(() => {
    if (campaignData) {
      setStateQuestions(_get(campaignData, 'questionario', []));
    }
  }, [campaignData]);

  const createFirstQuestion = async () => {
    const firstQuestion = {
      campanha_id: campaignID,
      final_fluxo: true,
      mascara: null,
      mensagem_inicial: true,
      proxima_pergunta_id: null,
      texto:
        'Olá, eu sou a primeira mensagem do seu chat. Clique em editar e altere o meu texto.',
      tipo: 'LABEL',
      variavel: null,
      variavel_integracao: null
    };
    await service.createQuestion(firstQuestion).then(response => {
      const question = response.data;
      question.opcoes = [null];
      setQuestions([question]);
    });
    SnackbarServiceInstance.showMessage({
      message: 'Mensagem de boas vindas criada.',
      variant: 'success'
    });
  };

  // console.log('Questions', questions);
  const [sidebarContentType, setSidebarContentType] = useState('DEFAULT');
  const [componentId, setComponentId] = useState(0);
  const [componentProps, setComponentProps] = useState(null);
  const [optionID, setOptionID] = useState(0);

  const setQuestions = async questions => {
    await service.updateQuestions(questions, campaignID);
    SnackbarServiceInstance.showMessage({
      message: 'Item adicionado com sucesso.',
      variant: 'success'
    });
    setStateQuestions(questions);
    dispatch(
      updateCampaign(campaignID, {
        questionario: questions
      })
    );
  };

  return (
    <>
      <Grid container alignContent="flex-start">
        <Grid item xs={12}>
          <Title>
            Roteiro
            <Tooltip
              placement="bottom"
              title={
                <p className="tooltip">
                  Aqui você montará o roteiro do chat, que será a interação com
                  seu usuário. Crie a ordem das perguntas e textos enviados pelo
                  bot e que tipo de dados vc quer captar do seu cliente.
                </p>
              }
            >
              <div>
                <IconButton icon="InfoCircle" />
              </div>
            </Tooltip>
          </Title>
        </Grid>
        <LeftSection item xs={6}>
          {!!questions.length && (
            <ConversationList
              setComponentId={setComponentId}
              cardList={createCardList(questions)}
              questions={questions}
              setQuestions={setQuestions}
              setComponentProps={setComponentProps}
              setSidebarContentType={setSidebarContentType}
              setOptionID={setOptionID}
            />
          )}
          {!questions.length && (
            <Button
              variant="outlined"
              style={{ marginLeft: '16px' }}
              onClick={createFirstQuestion}
            >
              Crie a mensagem de boas vindas
            </Button>
          )}
        </LeftSection>
        <RightSection item xs={6} style={{ fontFamily: 'Source Sans Pro' }}>
          <TreeSection>
            <Flow data={questions} />
          </TreeSection>
          <NavigationButtons
            backwardsAction={() => backwardsAction()}
            forwardAction={() => forwardAction()}
          />
        </RightSection>
      </Grid>
      <Drawer
        anchor="right"
        handleClick={() => setComponentId(0)}
        open={!!componentId}
      >
        {drawerContent(
          campaignID,
          componentId,
          setComponentId,
          sidebarContentType,
          setSidebarContentType,
          questions,
          setQuestions,
          componentProps,
          setComponentProps,
          optionID,
          setOptionID
        )}
      </Drawer>
    </>
  );
};
