import React, { useState } from 'react';
import { ColorPicker } from 'morpheus-base-react';
import { Slider } from '@material-ui/core';

export const CustomColor = ({
  onChangeColor,
  onChangeTransp,
  color,
  transp
}) => {
  const [transparency, setTransparency] = useState<number>(transp || 0);
  return (
    <div>
      <p>Cor do Botão</p>
      <ColorPicker
        defaultValue={color}
        onChange={value => onChangeColor(value)}
        style={{ marginTop: '30px', marginLeft: '30px' }}
      />
      <p>Transparência</p>
      <Slider
        value={transparency}
        aria-valuetext={String(transparency)}
        onChange={(evnt, value) => {
          setTransparency(value);
          onChangeTransp(value);
        }}
      />
    </div>
  );
};
