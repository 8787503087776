import React, { useState, useEffect, useCallback } from 'react';
import './customization-step.scss';
import '../../novo-formulario.scss';

import _get from 'lodash/get';

import { useSelector, useDispatch } from 'react-redux';
import { updateCampaign } from 'app/store/campaign/action';
import { Collapse, Grid, IconButton, Tooltip } from 'morpheus-base-react';

import { ChatContent } from './ChatContent';
import { CustomAvatar } from './CustomAvatar';
import { CustomColor } from './CustomColor';
// import { CustomButton } from './CustomButton';
import { CustomText } from './CustomText';
import { AdvancedSettings } from './AdvancedSettings';
import { NavigationButtons } from '../NavigationButtons';
import { isNaN } from 'app/utils/is-nan';

interface CustomizationStepProps {
  forwardAction: () => void;
  backwardsAction: () => void;
}

function updateChatProperty(props: Record<string, unknown>) {
  document.dispatchEvent(
    new CustomEvent('update-by-session', {
      bubbles: true,
      composed: true,
      detail: props
    })
  );
}

const DEFAULT_AVATAR = 'assets/images/select-photo-icon.png';

export const CustomizationStep = ({
  forwardAction,
  backwardsAction
}: CustomizationStepProps) => {
  const dispatch = useDispatch();
  const campaignData = useSelector((state: any) => state.campaign.campaign);

  const campaignID = _get(campaignData, 'campanha_id', 0);
  const fetchFontFamily = _get(campaignData, 'css.fontFamily', 'sans-serif');
  const fetchFontFamilyBodyChat = _get(
    campaignData,
    'css.fontFamilyBodyChat',
    'source sans pro'
  );
  let fetchFontSize = Number(_get(campaignData, 'css.fontSize', '16'));
  if (isNaN(fetchFontSize)) {
    fetchFontSize = 16;
  }
  const fetchAvatar = _get(campaignData, 'css.avatar', DEFAULT_AVATAR);
  const fetchAdvancedSettings = _get(
    campaignData,
    'css.advancedSettings.closeButton',
    true
  );

  const fetchColorScheme = _get(campaignData, 'css.colorPrimary', '#004de8');
  const fetchTextColor = _get(campaignID, 'css.colorText', '#FFF');

  const [avatar, setAvatar] = useState(fetchAvatar);
  const [colorPrimary, setColorPrimary] = useState(fetchColorScheme);
  const [textColor, setTextColor] = useState(fetchTextColor);
  const [fontFamily, setFontFamily] = useState(fetchFontFamily);
  const [fontSize, setFontSize] = useState(fetchFontSize);
  const [fontFamilyBodyChat, setFontFamilyBodyChat] = useState(
    fetchFontFamilyBodyChat
  );
  const [closeButton, setCloseButton] = useState(fetchAdvancedSettings);
  const chatButton = 'assets/images/chat-closed-1.png';

  function removeFile() {
    setAvatar(DEFAULT_AVATAR);
    saveAvatar(DEFAULT_AVATAR);
    updateChatProperty({
      chatIconClosed: DEFAULT_AVATAR,
      iconHeader: DEFAULT_AVATAR,
      chatIconMessage: DEFAULT_AVATAR
    });
  }

  function chooseFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.gif,.jpg,.jpeg,.png,.doc,.docx';
    input.setAttribute('data-max-size', '10240');
    input.onchange = (e: any) => {
      if (e != null) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setAvatar(reader.result);
          saveAvatar(reader.result);
          updateChatProperty({
            chatIconClosed: reader.result,
            iconHeader: reader.result,
            chatIconMessage: reader.result
          });
        };
        reader.onerror = () => {
          console.error(reader.error);
          alert('Erro ao inserir arquivo, tente novamente');
        };
      }
    };
    input.click();
  }

  const saveData = useCallback(
    (
      chatButton,
      closeButton,
      colorPrimary,
      fontFamily,
      fontFamilyBodyChat,
      fontSize,
      textColor
    ) => {
      console.log('saveData');
      dispatch(
        updateCampaign(campaignID, {
          css: {
            colorPrimary,
            chatButton,
            fontFamily,
            fontSize,
            fontFamilyBodyChat,
            advancedSettings: { closeButton },
            textColor
          }
        })
      );
    },
    [campaignID, dispatch]
  );

  useEffect(() => {
    saveData(
      chatButton,
      closeButton,
      colorPrimary,
      fontFamily,
      fontFamilyBodyChat,
      fontSize,
      textColor
    );
  }, [
    chatButton,
    closeButton,
    colorPrimary,
    fontFamily,
    fontFamilyBodyChat,
    fontSize,
    textColor,
    saveData
  ]);

  const saveAvatar = useCallback(
    avatar => {
      dispatch(
        updateCampaign(campaignID, {
          css: {
            avatar
          }
        })
      );
    },
    [campaignID, dispatch]
  );

  return (
    <>
      <Grid className="mph-wrapper" container>
        <Grid className="mph-left-content" item xs={3}>
          <h1 className="mph-title-h1">
            Customização
            <Tooltip
              placement="bottom"
              title={
                <p className="tooltip">
                  Essa página é onde é feita a customização do chat que será
                  apresentado ao usuário. Escolha as cores e fontes que lhe
                  agradam mais e adicione sua logo para personalização.
                </p>
              }
            >
              <div>
                <IconButton icon="InfoCircle" />
              </div>
            </Tooltip>
          </h1>
          <Collapse
            colorTitle="#20396a"
            color="#f2f3f9"
            expandedMultiples={false}
            collapseProps={[
              {
                title: 'IMAGEM DO AVATAR',
                disabled: false,
                content: (
                  <CustomAvatar
                    avatarImg={avatar}
                    onClick={chooseFile}
                    onRemove={removeFile}
                  />
                )
              },
              {
                title: 'PADRÃO DE COR',
                disabled: false,
                content: (
                  <CustomColor
                    msgDown={'Escolha a cor do texto do seu chat:'}
                    msgUp={'Escolha a cor do seu chat:'}
                    onChangeDown={value => setTextColor(value)}
                    onChangeUp={value => setColorPrimary(value)}
                  />
                )
              },
              {
                title: 'TEXTO',
                disabled: false,
                content: (
                  <CustomText
                    defaultFontSize={fontSize}
                    defaultFontFamily={fontFamily}
                    setFontFamily={setFontFamily}
                    setFontFamilyBodyChat={setFontFamilyBodyChat}
                    setFontSize={setFontSize}
                  />
                )
              },
              {
                title: 'AVANÇADO',
                disabled: false,
                content: (
                  <AdvancedSettings
                    setCloseButton={setCloseButton}
                    closeButton={closeButton}
                  />
                )
              }
            ]}
          />
        </Grid>
        <Grid className="mph-right-content" item xs={9}>
          <ChatContent
            avatar={avatar}
            color={colorPrimary}
            // chatButton={chatButton}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontFamilyBodyChat={fontFamilyBodyChat}
            textColor={textColor}
          />
        </Grid>
      </Grid>
      <footer className="mph-step-footer">
        <NavigationButtons
          forwardAction={() => forwardAction()}
          backwardsAction={() => backwardsAction()}
        />
      </footer>
    </>
  );
};
