import React, { Component } from 'react';

import moment from 'moment';

import './leads-table.scss';
import classNames from 'classnames';
import {
  SnackbarServiceInstance,
  PromptServiceInstance,
  Icon,
  Table,
  TableActionCallback,
  TableHeader,
  TableData,
  PagesChangeEvent,
  EmptyState
} from 'morpheus-base-react';
import { TableCSSProperties } from 'morpheus-base-react/dist/components/Table/Table';
import { humanizeDuration } from '../../utils/time-formatter';
import {
  CampanhaRespostasService,
  ResultadoRespostas,
  ItemResposta,
  UUID
} from 'service/campanha-respostas-service';

export interface LeadsTableProps {
  start: Date;
  end: Date;
  campaignId: any;
}

export interface LeadsTableState {
  page: number;
  perPage: number;
  data?: ResultadoRespostas;
}

function getHeaders(onDeletionCallback: TableActionCallback): TableHeader[] {
  return [
    {
      labelHeader: 'Nome',
      attr: 'Nome',
      id: '1',
      typeCell: 'string',
      sort: false,
      align: 'left',
      format: (value: string, _, item: TableData) => (
        <>
          <span
            className={classNames('leads-table__unread-indicator', {
              'leads-table__unread-indicator--unread': item.unread
            })}
          />
          <span>{value}</span>
        </>
      )
    },
    {
      labelHeader: 'E-mail',
      attr: 'E-mail',
      id: '2',
      typeCell: 'string',
      sort: false,
      align: 'left'
    },
    {
      labelHeader: 'Telefone',
      attr: 'Telefone',
      id: '3',
      typeCell: 'string',
      sort: false,
      align: 'left'
    },
    {
      labelHeader: 'Empresa',
      attr: 'Empresa',
      id: '4',
      typeCell: 'string',
      sort: false,
      align: 'left'
    },
    {
      labelHeader: 'Data',
      attr: 'Data',
      id: '5',
      typeCell: 'string',
      sort: false,
      align: 'left',
      format: (value: Date) => {
        const datetime = moment(value);
        return (
          <>
            <span className="leads-table__data__date">
              {datetime.format('DD/MM/YYYY')}
            </span>
            <span className="leads-table__data__time">
              {datetime.format('HH:mm')}
            </span>
          </>
        );
      }
    },
    {
      labelHeader: 'Status',
      attr: 'Status',
      id: '6',
      typeCell: 'string',
      sort: false,
      align: 'left',
      format: (value: boolean) => {
        if (value) {
          return (
            <div className="leads-table__status leads-table__status--completo">
              Completo
            </div>
          );
        }

        return (
          <div className="leads-table__status leads-table__status--incompleto">
            Incompleto
          </div>
        );
      }
    },
    {
      id: '7',
      typeCell: 'action',
      actions: [
        {
          action: (item: TableData) => onDeletionCallback(item),
          actionType: 'button',
          icone: 'Trash',
          iconeClass: 'fill-red'
        },
        {
          actionType: 'subtable',
          icone: 'AngleDown'
        }
      ]
    }
  ];
}

export class LeadsTable extends Component<LeadsTableProps, LeadsTableState> {
  private respostasService = new CampanhaRespostasService();

  state: LeadsTableState = { page: 1, perPage: 10 };

  componentDidMount() {
    this.buscarRespostas(this.state);
  }

  buscarRespostas(configuracaoPagina) {
    console.log('PROPS', this.props);
    this.respostasService
      .buscarPorCampanhaDetalhe(
        this.props.campaignId.value || this.props.campaignId,
        this.props.start,
        this.props.end,
        configuracaoPagina.page,
        configuracaoPagina.perPage
      )
      .then(response => response.data)
      .then(data =>
        this.setState({
          data,
          page: configuracaoPagina.page,
          perPage: configuracaoPagina.perPage
        })
      );
  }

  componentDidUpdate(prevProps: LeadsTableProps) {
    if (
      prevProps.start === this.props.start &&
      prevProps.end === this.props.end
    ) {
      return;
    }

    this.setState(state => {
      this.buscarRespostas({
        ...state,
        page: 1
      });

      return {
        page: 1
      };
    });
  }

  getTableData() {
    if (!this.state.data) {
      return [];
    }
    const paginaRespostas = this.state.data.pagina_respostas;
    return paginaRespostas.map((resposta, index) =>
      this.makeRowFromResposta(resposta, index)
    );
  }

  makeRowFromResposta(resposta: ItemResposta, index: number): TableData {
    return {
      key: String(index),
      cellsCSS: this.getCellsCSS(resposta),
      Nome: resposta.resposta.nome,
      'E-mail': resposta.resposta.email,
      Telefone: resposta.resposta.telephone,
      Empresa: resposta.resposta.empresa,
      Data: resposta.dt_criacao,
      Status: !!resposta.dt_finalizacao,
      unread: !resposta.visualizado_na_interface_web,
      source: resposta
    };
  }

  getCellsCSS(resposta: ItemResposta): TableCSSProperties {
    if (resposta.visualizado_na_interface_web) {
      return {};
    }

    return {
      fontWeight: 'bold'
    };
  }

  getDetalhamento(item: TableData) {
    const resposta = item.source;
    const respostas: any[] = resposta.questoes_com_respostas.filter(
      questao => questao.resposta_do_usuario
    );

    function getPerguntas() {
      if (respostas.length) {
        return respostas.map((questao, index) => (
          <div key={index} className="leads-table__subtable__question">
            <h2 className="leads-table__subtable__subtitle">{questao.texto}</h2>
            <span className="leads-table__subtable__text">
              {questao.resposta_do_usuario}
            </span>
          </div>
        ));
      }

      return (
        <EmptyState subtitle="Nenhuma pergunta foi respondida." fullWidth />
      );
    }
    return (
      <div className="leads-table__subtable">
        <div className="leads-table__subtable__detailing">
          <h1 className="leads-table__subtable__title">Detalhes</h1>
          <div className="leads-table__subtable__detailing-item">
            <Icon viewIcone="FileCheck" iconeClass="fill-blue" />
            <div>
              <h2 className="leads-table__subtable__subtitle">Respondidas</h2>
              <span className="leads-table__subtable__text">
                {resposta.quantidade_respondidas}
              </span>
            </div>
          </div>
          <div className="leads-table__subtable__detailing-item">
            <Icon viewIcone="Clock" iconeClass="fill-blue" />
            <div>
              <h2 className="leads-table__subtable__subtitle">
                Tempo total do fluxo
              </h2>
              <span className="leads-table__subtable__text">
                {humanizeDuration(resposta.tempo_total)}
              </span>
            </div>
          </div>
        </div>
        <div className="leads-table__subtable__questions">
          <h1 className="leads-table__subtable__title">Perguntas</h1>
          {getPerguntas()}
        </div>
      </div>
    );
  }

  onDetalhamento(customData: TableData) {
    const itemResposta: ItemResposta = customData.source;

    if (itemResposta.visualizado_na_interface_web) {
      return;
    }

    this.respostasService.marcarLido(itemResposta.id);

    customData.unread = false;

    this.state.data &&
      this.state.data.pagina_respostas.forEach(pagina => {
        if (pagina.id === itemResposta.id) {
          pagina.visualizado_na_interface_web = true;
        }
      });
  }

  onPagination(pageChangesEvent: PagesChangeEvent) {
    this.setState({
      page: pageChangesEvent.page,
      perPage: pageChangesEvent.rowsPerPage
    });

    this.buscarRespostas({
      page: pageChangesEvent.page,
      perPage: pageChangesEvent.rowsPerPage
    });
  }

  onDelecao(resposta: ItemResposta) {
    PromptServiceInstance.showPrompt({
      title: 'Remover o lead?',
      message: 'Esta ação não pode ser desfeita',
      buttons: {
        confirm: {
          caption: 'Sim',
          props: {
            color: 'danger'
          },
          action: () => {
            this.removerLead(resposta.id);
          }
        },
        cancel: {
          caption: 'Manter',
          action: () => {
            SnackbarServiceInstance.showMessage({
              message: 'O Lead não foi removido.'
            });
          }
        }
      }
    });
  }

  removerLead(id: UUID) {
    this.respostasService.delete(id).then(() => {
      SnackbarServiceInstance.showMessage({ message: 'Lead removido.' });

      this.setState({ page: 1 });

      this.buscarRespostas({
        ...this.state,
        page: 1
      });
    });
  }

  render() {
    if (!this.state.data || !this.state.data.total_respostas) {
      // retorna null pq o componente de cima ficaria do mesmo jeito
      return null;
    }

    const headers = getHeaders(item => this.onDelecao(item.source));

    return (
      <>
        <Table
          headers={headers}
          data={this.getTableData()}
          pagination
          clientSidePagination={false}
          rowsPerPageOptions={[10, 20, 30]}
          colSpanPagination={7}
          detail={this.getDetalhamento}
          paginationConfig={{
            count: (this.state.data && this.state.data.total_respostas) || 0,
            page: this.state.page,
            totalPages: (this.state.data && this.state.data.total_paginas) || 0,
            rowsPerPage: this.state.perPage
          }}
          onDetailing={TableData => this.onDetalhamento(TableData)}
          actionPagination={pageChangesEvent =>
            this.onPagination(pageChangesEvent)
          }
          footer={
            <>
              <div className="leads-table__footer-leads-count">
                Total de Leads:{' '}
                <strong>
                  {(this.state.data && this.state.data.total_respostas) || 0}
                </strong>
              </div>
            </>
          }
        />
      </>
    );
  }
}
