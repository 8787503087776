import React, { useState } from 'react';
import { styled } from '@material-ui/styles';
import { Slider } from '@material-ui/core';
import { Avatar, IconButton, Button } from 'morpheus-base-react';

interface CustomMarkProps {
  avatarImg: string;
  onClick: () => void;
  onRemove: () => void;
  OnChangeHeight: any;
  heig: any;
}

const SizeDisclaimer = styled('p')({
  fontFamily: 'Source Sans Pro',
  fontSize: '12px',
  color: '#62708b',
  textAlign: 'center',
  margin: '0px'
});

export const CustomMark = ({
  avatarImg,
  onClick,
  onRemove,
  OnChangeHeight,
  heig
}: CustomMarkProps) => {
  const [height, setHeight] = useState<number | Array<number>>(heig);

  return (
    <div>
      Selecione a imagem de logo para o seu template:
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
          marginTop: '10px',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div>
          <Avatar src={avatarImg} size="64px">
            Marca
          </Avatar>
          <a
            onClick={onClick}
            style={{
              position: 'relative',
              bottom: '15px',
              left: '19px'
            }}
          >
            <IconButton
              size={24}
              backgroundColor="primary"
              icon="PlusAdd"
              iconStyle={{ position: 'absolute', top: '4px' }}
            />
          </a>
        </div>
        <Button variant="text" onClick={onRemove}>
          Remover
        </Button>
      </div>
      <SizeDisclaimer>Altura recomendada de 42px.</SizeDisclaimer>
      <p>Altura</p>
      <Slider
        min={40}
        max={124}
        aria-valuetext={String(height)}
        defaultValue={height}
        valueLabelDisplay="on"
        onChange={(evnt, value) => {
          setHeight(value);
          OnChangeHeight(value);
        }}
      />
    </div>
  );
};
