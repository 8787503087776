import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';
import { Campaign } from 'app/store/campaign/type';

export class CampaignService {
  private static url = 'campanhas/confs/';

  public async fetchCampaign(id: number): Promise<AxiosResponse<Campaign>> {
    return HttpClient.get(`${CampaignService.url}campanha/${id}`);
  }

  public async createCampaign(
    nome: number,
    descricao: string,
    detalhe_tipo: string
  ): Promise<AxiosResponse<Campaign>> {
    return HttpClient.post(CampaignService.url, {
      nome,
      descricao,
      detalhe_tipo
    });
  }

  public async updateCampaign(
    id: number,
    fields: Partial<Campaign>
  ): Promise<AxiosResponse<Campaign>> {
    return HttpClient.put(CampaignService.url, {
      id,
      ...fields
    });
  }

  public async clearCampaign() {
    return { data: {} };
  }
}
