import React, { createContext, useState } from 'react';

export type UserData = {
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
  emailVerified: boolean;
};

export type AuthData = {
  user?: UserData;
  setUser: (user?: UserData) => void;
};

export const AuthContext = createContext({} as AuthData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserData>();
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
