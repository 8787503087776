import React from 'react';

import './template.scss';

export const TemplateContent = ({
  img,
  logo,
  title,
  titleFontFamily,
  titleFontSize,
  text,
  textFontFamily,
  textFontSize,
  button,
  textButton,
  color,
  transp,
  heig
}) => {
  return (
    <>
      <div
        className="mph-template-content"
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <img
          src={logo}
          className="logo"
          style={{
            height: heig + 'px'
          }}
        />
        <div
          className="title"
          style={{
            fontFamily: titleFontFamily,
            fontSize: titleFontSize
          }}
        >
          {title}
        </div>
        <div
          className="text-content"
          style={{
            fontFamily: textFontFamily,
            fontSize: textFontSize
          }}
        >
          {text}
        </div>
        <div className="button">
          <button
            style={{
              borderRadius: button === 'redondo' ? '10em' : 'initial',
              backgroundColor: color,
              opacity: 1 - transp / 100,
              color: color === '#ffffff' ? '#000000' : '#ffffff',
              border: 'none'
            }}
          >
            {textButton}
          </button>
        </div>
      </div>
    </>
  );
};
