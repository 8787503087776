import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/styles';

import { Tooltip } from '@material-ui/core';
import '../../novo-formulario.scss';
import {
  Button,
  IconButton,
  SnackbarServiceInstance,
  TextField
} from 'morpheus-base-react';
import { updateCampaign } from 'app/store/campaign/action';

import { NavigationButtons } from '../NavigationButtons';

import './publishing-step.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CopyBlock, atomOneLight } from 'react-code-blocks';

interface PublishingStepProps {
  backwardsAction: () => void;
  finishAction: () => void;
}

const Wrapper = styled('div')({
  height: 'calc(100% - 66px)'
});

const Title = styled('h1')({
  alignItems: 'center',
  backgroundColor: 'white',
  color: '#62708b',
  display: 'flex',
  fontSize: '24px',
  fontWeight: 600,
  height: '38px',
  justifyContent: 'flex-start',
  margin: 0,
  fontFamily: 'Source Sans Pro',
  padding: '10px 16px',
  '& path': {
    fill: '#62708b !important'
  }
});

const Footer = styled('footer')({
  height: '66px',
  width: '100%'
});

const Content = styled('div')({
  minHeight: '100%',
  height: 'auto !important'
});

const SectionTitle = styled('h2')({
  fontSize: '16px',
  fontWeight: 'bold',
  fontFamily: 'Source Sans Pro',
  color: '#62708b'
});

const PublishContent = styled('div')({
  padding: '0px 16px'
});

const AccessLink = styled('a')({
  fontSize: '16px',
  lineHeight: '1.25',
  color: '#004de8',
  fontFamily: 'Source Sans Pro'
});

const SectionDiv = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

export const PublishingStep = ({
  backwardsAction,
  finishAction
}: PublishingStepProps) => {
  const [tagUrl, setTagUrl] = useState();
  const dispatch = useDispatch();
  const campaignData = useSelector((state: any) => state.campaign.campaign);

  let url = undefined;
  if (campaignData && campaignData['tag_url']) {
    url = process.env.BASE_URL_CLIENT + campaignData['tag_url'];
  }

  useEffect(() => {
    if (campaignData && campaignData['tag_url']) {
      setTagUrl(campaignData['tag_url']);
    }
  }, [campaignData]);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    SnackbarServiceInstance.showMessage({
      message: 'URL copiada com sucesso.',
      variant: 'success'
    });
  };

  const handleSaveTag = async () => {
    await dispatch(
      updateCampaign(campaignData['campanha_id'], {
        tag_url: tagUrl
      })
    );
    SnackbarServiceInstance.showMessage({
      message: 'URL atualizada com sucesso.',
      variant: 'success'
    });
  };
  const scriptCodeImport =
    '<script type="text/javascript" src="https://form.gump.ai/gump.form.js"></script>';
  const scriptCodeChat = `<button onclick="window.mphQuestionaire.configureByURL('${campaignData.tag_url}'); window.mphQuestionaire.start();">teste</button>`;

  return (
    <>
      <Wrapper>
        <Content>
          <Title>
            Publicação
            <Tooltip
              placement="bottom"
              title={
                <p className="tooltip">
                  Pronto, seu formulário foi criado. Veja o link para acesso e
                  como compartilhar e ver os resultados.
                </p>
              }
            >
              <div>
                <IconButton icon="InfoCircle" />
              </div>
            </Tooltip>
          </Title>
          <PublishContent>
            {url && (
              <>
                <SectionTitle>Preview de publicação:</SectionTitle>
                <SectionDiv>
                  <AccessLink href={url} target="_blank">
                    {url}
                  </AccessLink>
                  {navigator && navigator.clipboard && (
                    <IconButton
                      icon="Copy"
                      color="primary"
                      onClick={handleCopy}
                    />
                  )}
                </SectionDiv>
                <SectionTitle>Alterar url:</SectionTitle>
                <SectionDiv>
                  <TextField
                    type="text"
                    variant="outlined"
                    defaultValue={campaignData['tag_url']}
                    onChange={e => setTagUrl(e.target.value)}
                  />
                  <Button style={{ marginLeft: '8px' }} onClick={handleSaveTag}>
                    Atualizar
                  </Button>
                </SectionDiv>
                {(campaignData.tipo === 'CHAT' ||
                  campaignData.tipo === 'PAGINA_INTEIRA') && (
                  <>
                    <SectionTitle>
                      1. Copie e cole este código em qualquer lugar da seção do
                      seu site:
                    </SectionTitle>
                    <CopyBlock
                      text={scriptCodeImport}
                      language={'html'}
                      showLineNumbers={true}
                      wrapLines
                      theme={atomOneLight}
                    />
                    <SectionTitle>
                      2. Copie e cole este código no botão do seu site:
                    </SectionTitle>
                    <CopyBlock
                      text={scriptCodeChat}
                      language={'html'}
                      showLineNumbers={true}
                      wrapLines
                      theme={atomOneLight}
                    />
                  </>
                )}
              </>
            )}
          </PublishContent>
        </Content>
      </Wrapper>
      <Footer>
        <NavigationButtons
          forwardAction={() => finishAction()}
          forwardLabel="Finalizar"
          backwardsAction={() => backwardsAction()}
        />
      </Footer>
    </>
  );
};
