import React from 'react';
import { BaseSelect } from './baseSelect';

export const Select = ({
  campaignID,
  componentID,
  componentProps,
  setComponentProps,
  setComponentId,
  questions,
  setQuestions,
  setContentSideBar,
  optionID,
  setOptionID,
  type
}) => {
  return (
    <BaseSelect
      props={{
        campaignID,
        componentID,
        componentProps,
        setComponentProps,
        setComponentId,
        questions,
        setQuestions,
        setContentSideBar,
        optionID,
        setOptionID
      }}
      title="Seleção única"
      type={type}
      icon="radio"
    />
  );
};
