import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { duration } from 'moment';
import {
  TableHeader,
  TableData,
  Table,
  PieChart,
  PieSlice,
  EmptyState,
  Card
} from 'morpheus-base-react';
import { EstatisticasPergunta } from 'service/campanha-estatisticas-service';

import ModalCustom from '../custom-components/modal-custom/modal-custom';
import { formatDate, humanizeDuration } from '../../utils/time-formatter';
import { formatDecimal } from '../../utils/number-formatter';

const HeaderDetalhe = (props: AnswersTableProps) => {
  function getTextoVigencia() {
    if (props.validity) {
      return (
        <>
          {formatDate(props.validity.start)} até
          {` ${formatDate(props.validity.end)}`}
        </>
      );
    }

    return <>Dados indisponíveis</>;
  }

  const TextInfoCommonProps: any = {
    sm: 12,
    md: 6,
    className: 'mph-item-detalhe'
  };

  return (
    <div className="mph-header-detalhe">
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item sm={12} lg={8}>
          <Grid container>
            <Grid item {...TextInfoCommonProps}>
              <span>Campanha</span>
              <span className="mph-label-pergunta">{props.campaignName}</span>
            </Grid>
            <Grid item {...TextInfoCommonProps}>
              <span>Vigência</span>
              <span className="mph-label-pergunta">{getTextoVigencia()}</span>
            </Grid>
            <Grid item {...TextInfoCommonProps}>
              <span>Temp. Médio de resposta</span>
              <span className="mph-label-pergunta">
                {humanizeDuration(props.averageAnswerMiliseconds)}
              </span>
            </Grid>
            <Grid item {...TextInfoCommonProps}>
              <span>Pergunta com maior engajamento</span>
              <span className="mph-label-pergunta">
                {props.mostEngagingQuestion}
              </span>
            </Grid>
            <Grid item {...TextInfoCommonProps}>
              <span>Pergunta com menor engajamento</span>
              <span className="mph-label-pergunta">
                {props.leastEngagingQuestion}
              </span>
            </Grid>
            <Grid item {...TextInfoCommonProps}>
              <span>Taxa de conversão</span>
              <span className="mph-label-pergunta">
                {props.conversionRate * 100}%
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} lg={4}>
          <div className="mph-card-table">
            <div className="border-green">
              <p>Respondidas</p>
              <span>{props.answeredCount}</span>
            </div>
            <div className="border-red">
              <p>S.Respostas</p>
              <span>{props.notAnsweredCount}</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const paramsPagination = {
  count: 10,
  rowsPerPage: 10,
  page: 1,
  totalPages: 20
};

function getHeaders(detailingAction: any): TableHeader[] {
  return [
    // {
    //     labelHeader: "Teste",
    //     attr: "teste",
    //     id: "1",
    //     typeCell: "checkbox",
    //     sort: false,
    //     align: "left"
    // },
    {
      labelHeader: 'Pergunta',
      attr: 'Pergunta',
      id: '2',
      typeCell: 'string',
      sort: false,
      align: 'left'
    },

    {
      labelHeader: 'Qtd. Respostas',
      attr: 'Qtd. Respostas',
      id: '3',
      typeCell: 'string',
      sort: false,
      format: (value: any) => (
        <>
          <span>{value} </span>
          <span style={{ color: 'green' }}>respostas</span>
        </>
      )
    },

    {
      labelHeader: 'Respondidas',
      attr: 'Respondidas',
      id: '4',
      typeCell: 'string',
      sort: false,
      format: (value: any) => <span style={{ color: '#2ecc71' }}>{value}</span>
    },

    {
      labelHeader: 'Não respondidas',
      attr: 'Não respondidas',
      id: '5',
      typeCell: 'string',
      sort: false,
      format: (value: any) => <span style={{ color: '#ff003a' }}>{value} </span>
    },
    {
      labelHeader: 'Tempo de resposta',
      attr: 'Tempo de resposta',
      id: '6',
      typeCell: 'string',
      sort: false,
      format: (value: any) => (
        <>
          <b>{formatDecimal(value, 1)} </b>
          <span style={{ color: 'green' }}>segundos</span>
        </>
      )
    },
    {
      id: '7',
      typeCell: 'action',
      actions: [
        {
          action: detailingAction,
          actionType: 'button',
          icone: 'Eye',
          hideIf: linha => !linha.raw.is_escolha_opcao
        }
        // { actionType: "subtable", icone: "Print" }
      ]
    }
  ];
}

export interface AnswersTableProps {
  answers: EstatisticasPergunta[];
  campaignName: string;
  validity?: {
    start: Date;
    end?: Date;
  };
  averageAnswerMiliseconds: number;
  mostEngagingQuestion?: string;
  leastEngagingQuestion?: string;
  conversionRate: number;
  answeredCount?: number;
  notAnsweredCount?: number;
}

interface AnswerTableState {
  isDetailing: boolean;
  isDetailingEmpty: boolean;
  detailed: PieSlice[];
}

export default class AnswersTable extends Component<
  AnswersTableProps,
  AnswerTableState
> {
  public state = {
    isDetailing: false,
    detailed: [],
    isDetailingEmpty: true
  };

  openDetailing(data) {
    const est: EstatisticasPergunta = data.raw;
    const detailed: PieSlice[] = est.estatisticas.respostas.map(
      respostasEscolhidas => ({
        name: respostasEscolhidas.chave_resposta,
        value: respostasEscolhidas.quantidade_selecoes
      })
    );
    this.setState({
      isDetailing: true,
      detailed,
      isDetailingEmpty: !!est.estatisticas.quantidade_pessoas_responderam
    });
  }

  getDetailingModelContent() {
    if (this.state.isDetailingEmpty) {
      return <PieChart data={this.state.detailed} />;
    }

    return (
      <EmptyState subtitle="Nenhuma resposta foi dada para esta pergunta ainda." />
    );
  }

  render() {
    const { answers } = this.props;

    const processedData: TableData[] = answers.map(dadosPergunta => ({
      key: String(dadosPergunta.id),
      Pergunta: dadosPergunta.texto,
      'Qtd. Respostas': dadosPergunta.estatisticas.total,
      Respondidas: dadosPergunta.estatisticas.quantidade_pessoas_responderam,
      'Não respondidas':
        dadosPergunta.estatisticas.quantidade_pessoas_nao_responderam,
      'Tempo de resposta': duration(
        dadosPergunta.estatisticas.tempo_medio_de_resposta,
        'milliseconds'
      ).asSeconds(),
      raw: dadosPergunta
    }));

    return (
      <>
        <ModalCustom
          title="Respostas escolhidas"
          handleClose={() => this.setState({ isDetailing: false })}
          open={this.state.isDetailing}
        >
          {this.getDetailingModelContent()}
        </ModalCustom>

        <Card>
          <Table
            headers={getHeaders(data => this.openDetailing(data))}
            data={processedData}
            title="RESPOSTAS"
            head={<HeaderDetalhe {...this.props} />}
            pagination
            clientSidePagination
            rowsPerPageOptions={[10, 20, 30]}
            colSpanPagination={7}
            alignPagination="right"
            paginationConfig={paramsPagination}
          />
        </Card>
      </>
    );
  }
}
