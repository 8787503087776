import { ActionsUnion, DispatchAction } from '../type';
import { createAction } from '../action';
import { User } from './type';
import { UsuarioService } from 'service/usuario-service';
import { SnackbarServiceInstance } from 'morpheus-base-react';

const userService = new UsuarioService();

export enum ActionTypes {
  GetUser = 'GetUser',
  UpdateUser = 'UpdateUser',
  ErrorUser = 'ErrorUser'
}

export interface GetUserOptions {
  user?: User;
  error?: Record<string, unknown>;
}

export const Actions = {
  getUser: (options: GetUserOptions) =>
    createAction(ActionTypes.GetUser, options),
  updateUser: (options: GetUserOptions) =>
    createAction(ActionTypes.UpdateUser, options),
  errorUser: (options: GetUserOptions) =>
    createAction(ActionTypes.ErrorUser, options)
};

export type Actions = ActionsUnion<typeof Actions>;

export function getUser(): DispatchAction {
  return async dispatch => {
    userService
      .getUser()
      .then(response => {
        dispatch(
          Actions.getUser({
            user: response.data
          })
        );
      })
      .catch(error => {
        console.error('ERROR', error);
        dispatch(Actions.errorUser({ error: error }));
      });
  };
}

export function updateUser(data): DispatchAction {
  return async () =>
    userService
      .updateUser(data)
      .then(() => {
        SnackbarServiceInstance.showMessage({
          message: 'Usuário atualizado!',
          variant: 'success'
        });
      })
      .catch(() => {
        SnackbarServiceInstance.showMessage({
          message: 'Ops! Tivemos um erro ao tentar atulizar este usuário',
          variant: 'error'
        });
      });
}

export function deleteUser(): DispatchAction {
  return async () => {
    userService.deleteUser().then(() => {
      document.dispatchEvent(new CustomEvent('logout'));
    });
  };
}
