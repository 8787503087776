import React from 'react';
import './customization-step.scss';

import { ColorPicker } from 'morpheus-base-react';

export const CustomColor = ({ onChangeUp, msgUp, msgDown, onChangeDown }) => {
  return (
    <>
      <p className="mph-custom-color-title">{msgUp}</p>
      <ColorPicker
        className="mph-custom-color-picker"
        onChange={value => onChangeUp(value)}
      />
      <p className="mph-custom-color-title">{msgDown}</p>
      <ColorPicker
        className="mph-custom-color-picker"
        onChange={value => onChangeDown(value)}
      />
    </>
  );
};
