import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';

export interface Opcoes {
  campanha_pergunta_id: number;
  chave: string;
  classe_css: string;
  id: number;
  ordem?: any;
  proxima_pergunta_id?: number;
  valor: string;
}

export interface QuestoesComResposta {
  campanha_id: number;
  final_fluxo: boolean;
  id: number;
  mascara: string;
  mensagem_inicial: boolean;
  opcoes: Opcoes[];
  proxima_pergunta_id?: number;
  resposta_do_usuario?: any;
  texto: string;
  tipo: string;
  variavel: string;
  variavel_integracao?: any;
}

export interface Resposta {
  [chaveResposta: string]: any;
}

export interface ItemResposta {
  campanha_detalhe_id: number;
  dados_usuario: any; // to be ignored!
  dt_criacao: Date;
  dt_finalizacao?: Date;
  id: string;
  quantidade_respondidas: number;
  questoes_com_respostas: QuestoesComResposta[];
  resposta: Resposta;
  tempo_total: number;
  versao_campanha_detalhe: number;
  visualizado_na_interface_web: boolean;
}

export interface Opcoes2 {
  campanha_pergunta_id: number;
  chave: string;
  classe_css: string;
  id: number;
  ordem?: any;
  proxima_pergunta_id?: number;
  valor: string;
}

export interface Questionario {
  campanha_id: number;
  final_fluxo: boolean;
  id: number;
  mascara: string;
  mensagem_inicial: boolean;
  opcoes: Opcoes2[];
  proxima_pergunta_id?: number;
  resposta_do_usuario?: any;
  texto: string;
  tipo: string;
  variavel: string;
  variavel_integracao?: any;
}

export interface ResultadoRespostas {
  is_ultima_pagina: boolean;
  pagina_respostas: ItemResposta[];
  questionario: Questionario[];
  total_paginas: number;
  total_respostas: number;
}

export type UUID = string;

export class CampanhaRespostasService {
  private static resource = '/campanhas/respostas';

  public async buscarPorCampanhaDetalhe(
    campanhaDetalheId: number,
    inicio: Date,
    fim: Date,
    page: number,
    size: number
  ): Promise<AxiosResponse<ResultadoRespostas>> {
    return HttpClient.get(
      `${CampanhaRespostasService.resource}/campanha_detalhe/${campanhaDetalheId}`,
      {
        params: {
          page,
          size,
          inicio,
          fim
        }
      }
    );
  }

  public async get(idResposta: UUID): Promise<AxiosResponse<ItemResposta>> {
    return HttpClient.get(`${CampanhaRespostasService.resource}/${idResposta}`);
  }

  public async marcarLido(idResposta: UUID) {
    return this.patch(idResposta, {
      visualizado_na_interface_web: true
    });
  }

  public async delete(idResposta: UUID) {
    return HttpClient.delete(
      `${CampanhaRespostasService.resource}/${idResposta}`
    );
  }

  private async patch(idResposta: UUID, atributos: Partial<ItemResposta>) {
    return HttpClient.patch(
      `${CampanhaRespostasService.resource}/${idResposta}`,
      atributos
    );
  }
}
