import axios, { AxiosError, AxiosInstance } from 'axios';
import * as HttpStatus from 'http-status-codes';
import { SnackbarServiceInstance, Logger } from 'morpheus-base-react';

const identity = <T>(value: T) => value;

const onError = (error: AxiosError) => {
  Logger.debugIfDebugging(() => ['Error during request', error]);

  if (!error || !error.response || !error.response.status) {
    SnackbarServiceInstance.showMessage({
      message: 'Erro de comunicação com o servidor',
      variant: 'error'
    });
    return Promise.reject(error);
  }

  const { response } = error;

  if (response.status === HttpStatus.GATEWAY_TIMEOUT) {
    SnackbarServiceInstance.showMessage({
      message: 'Erro de comunicação com o servidor',
      variant: 'error'
    });
    return Promise.reject(error);
  }

  if (
    response.status === HttpStatus.UNAUTHORIZED ||
    response.status === HttpStatus.FORBIDDEN
  ) {
    SnackbarServiceInstance.showMessage({
      message: 'Sessão expirada! Redirecionando para login.',
      variant: 'warn'
    });

    document.dispatchEvent(new CustomEvent('logout'));
    window.location.href = '/';
    return Promise.reject(error);
  }

  if (
    response.status === HttpStatus.BAD_REQUEST &&
    response.data &&
    response.data.message
  ) {
    SnackbarServiceInstance.showMessage({
      message: `Erro: ${response.data.message}`,
      variant: 'error'
    });

    return Promise.reject(error);
  }

  if (response.status === 406) {
    SnackbarServiceInstance.showMessage({
      message: 'Verifique o link enviado para seu email.',
      variant: 'warning'
    });
  }

  return Promise.reject(error);
};

export const HttpClientFactory = ({
  baseURL
}: {
  baseURL: string;
}): AxiosInstance => {
  const CustomAxios = axios.create({
    baseURL,
    withCredentials: true
  });

  CustomAxios.interceptors.response.use(identity, onError);

  CustomAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('gump-app-tkn');
    config.headers.Authorization = token;
    return config;
  });

  return CustomAxios;
};

export const BASE_URL = process.env.BASE_URL;
export const HttpClient = HttpClientFactory({ baseURL: BASE_URL });
