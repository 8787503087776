import React, { useState } from 'react';
import { map as _map } from 'lodash';
import { ConversationCard } from './ConversationCard';
import { styled } from '@material-ui/styles';
import { Button } from 'morpheus-base-react';
import PlusAdd from 'svg-react-loader!assets/images/plus-add.svg';
import { fetchIcon } from './fetchIcon';
import { CampaignQuestionService } from '../../../../../service/campaign-pergunta-service';

import { Modal } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      padding: 24,
      outline: 'none',
      borderRadius: '8px',
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.16)'
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cancelButton: {
      marginRight: '12px',
      color: '#62708b'
    },
    removeButton: {
      color: '#ffffff',
      borderColor: '#ff003a',
      backgroundColor: '#ff003a'
    }
  })
);

export interface ConversationCardItem {
  id: number;
  icon: string;
  title: string;
  order: number;
  content: React.ReactNode;
  nextQuestion: number | null;
  type: string;
  options: any;
}

interface ConversationListProps {
  cardList: ConversationCardItem[];
  setComponentId: (number) => void;
  questions: any;
  setQuestions: any;
  setComponentProps: any;
  setSidebarContentType: (string) => void;

  setOptionID: (number) => void;
}

const Wrapper = styled('div')({
  padding: '8px'
});

const AddButton = styled('button')({
  margin: '4px 15px',
  borderRadius: '100%',
  height: '24px',
  width: '24px',
  backgroundColor: '#20396a',
  cursor: 'pointer',
  border: 0,
  padding: 0,
  overflow: 'hidden',
  '& svg': {
    height: '13px',
    width: '14px'
  }
});

export const ConversationList = ({
  cardList,
  setComponentId,
  questions,
  setQuestions,
  setComponentProps,
  setSidebarContentType,
  setOptionID
}: ConversationListProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(0);
  const classes = useStyles();

  const removeQuestions = index => {
    console.log('Removendo pergunta', index);
    const copyQuestions = [...questions];
    const targetQuestion = copyQuestions[index - 1];

    copyQuestions.forEach(question => {
      question.opcoes.forEach(option => {
        if (option && option.proxima_pergunta_id == targetQuestion.id) {
          option.proxima_pergunta_id = targetQuestion.proxima_pergunta_id;
        }
      });

      if (question.proxima_pergunta_id == targetQuestion.id) {
        question.proxima_pergunta_id = targetQuestion.proxima_pergunta_id;
        let final;
        question.proxima_pergunta_id != null ? (final = false) : (final = true);
        question.final_fluxo = final;
      }
    });

    const serviceApi = new CampaignQuestionService();
    serviceApi.filterQuestions(copyQuestions).then(result => {
      setQuestions(result.data);
    });
  };

  const config = index => {
    setComponentProps(questions[index - 1]);

    const TYPES = {
      LABEL: 'text',
      INPUT_TEXT: 'message',
      MAIL: 'email',
      BUTTON: 'button',
      SELECT: 'select',
      RADIO: 'radio',
      CHECKBOX: 'checkbox',
      GIF: 'image',
      HTML: 'text'
    };
    setSidebarContentType(TYPES[questions[index - 1].tipo]);
    setComponentId(index);
  };

  const openSidebar = index => {
    setSidebarContentType('DEFAULT');
    setComponentId(index + 1);
  };

  return (
    <Wrapper>
      <Modal
        className={classes.modal}
        open={!!openModalDelete}
        onClose={() => setOpenModalDelete(0)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          {fetchIcon('trashModal')}
          <h2
            style={{
              color: '#20396a',
              fontSize: '18px',
              font: 'Source Sans Pro',
              fontWeight: 'bold'
            }}
          >
            Deseja mesmo excluir o componente?
          </h2>
          <p
            style={{
              color: '#62708b',
              fontSize: '16px',
              font: 'Source Sans Pro'
            }}
          >
            O componente selecionado será excluido junto com os componentes de
            seu fluxo. Após você excluir os mesmos não poderão ser recuperados.
            Deseja continuar?
          </p>
          <div style={{ display: 'flex', float: 'right', marginTop: '24px' }}>
            <Button
              className={classes.cancelButton}
              size="small"
              variant="text"
              onClick={() => setOpenModalDelete(0)}
            >
              <span>Cancelar</span>
            </Button>
            <Button
              className={classes.removeButton}
              variant="outlined"
              size="small"
              color="inverse"
              onClick={() => {
                removeQuestions(openModalDelete);
                setOpenModalDelete(0);
              }}
            >
              <span>Excluir</span>
            </Button>
          </div>
        </div>
      </Modal>
      {_map(cardList, (card, index) => {
        const nextQuestion = cardList.findIndex(
          item => item.id === card.nextQuestion
        );
        return (
          <div key={index}>
            <ConversationCard
              cardList={cardList}
              firstCard={index === 0}
              order={index}
              nextQuestion={nextQuestion}
              icon={card.icon}
              title={card.title}
              content={card.content}
              onClickDelete={() => setOpenModalDelete(index + 1)}
              onClickConfig={() => config(index + 1)}
              type={card.type || ''}
              options={card.options || null}
              setOptionID={setOptionID}
              openSidebar={openSidebar}
            />

            <AddButton onClick={() => openSidebar(index)}>
              <PlusAdd />
            </AddButton>
          </div>
        );
      })}
    </Wrapper>
  );
};
