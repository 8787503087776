import React from 'react';
import './leads-and-rejection-chart.scss';
import { PerDayChart, EmptyState } from 'morpheus-base-react';

export default function LeadsAndRejectionChart(props: any) {
  function getContent() {
    if (props.data.length) {
      return (
        <PerDayChart
          enableSecondaryValue
          valueLabel="Leads"
          secondaryValueLabel="Rejeição"
          data={props.data}
        />
      );
    }

    return (
      <>
        <EmptyState subtitle="Não houve nenhuma informação de leads no período selecionado" />
      </>
    );
  }

  return <div className="leads-and-rejection-chart__chart">{getContent()}</div>;
}
