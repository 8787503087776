import React from 'react';
import { styled } from '@material-ui/styles';

const Wrapper = styled('div')({
  maxWidth: '120px',
  textAlign: 'center',
  fontFamily: 'Source Sans Pro'
});

const Text = styled('p')({
  color: '#62708b',
  fontSize: '14px',
  fontFamily: 'Source Sans Pro'
});

const Name = styled('strong')({
  fontWeight: 600,
  color: '#20396a'
});

export const StepperLabel = ({ name }) => {
  return (
    <Wrapper>
      <Text>
        Olá <Name>{name}</Name>, vamos configurar sua conta?
      </Text>
    </Wrapper>
  );
};
