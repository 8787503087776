import React from 'react';
import { Radio, TextField } from 'morpheus-base-react';

export const CustomButton = ({
  setButton,
  setTextButton,
  button,
  textButton
}) => {
  return (
    <div>
      Tipo de botão:
      <div>
        <Radio
          name="nameClosedButton"
          color={'primary'}
          onChange={val => setButton(val.target.value)}
          value={button}
          options={[
            {
              label: (
                <div
                  style={{
                    background: 'rgba(173, 188, 216, 0.48)',
                    border: '1px solid rgb(36, 55, 91)',
                    padding: '2px'
                  }}
                >
                  <label style={{ textAlign: 'center' }}>Quadrado</label>
                </div>
              ),
              value: 'quadrado'
            },
            {
              label: (
                <div
                  style={{
                    background: 'rgba(173, 188, 216, 0.48)',
                    border: '1px solid rgb(36, 55, 91)',
                    borderRadius: '10em',
                    padding: '6px'
                  }}
                >
                  <label style={{ textAlign: 'center' }}>Redondo</label>
                </div>
              ),
              value: 'redondo'
            }
          ]}
        />
      </div>
      Texto do botão
      <div>
        <TextField
          type={'text'}
          label={'Digite o texto'}
          variant={'filled'}
          defaultValue={textButton}
          onChange={value => setTextButton(value.target.value)}
          fullWidth
        />
      </div>
    </div>
  );
};
