import React from 'react';
import { BasePage, Button } from 'morpheus-base-react';

import './error404.scss';

export const Error404 = () => {
  return (
    <BasePage margin="8px">
      <div className="notFound">
        <img src={'../../../assets/images/image404.png'} alt="404" />
        <div className="titleNotFound">Eiiiita!</div>
        <div className="textNotFound">Parece que essa página não existe.</div>
        <div className="textNotFound">
          Mas fique tranquilo, clique no botão abaixo e seja redirecionado para
          nossa home.
        </div>
        <Button>Leve-me para a home</Button>
      </div>
    </BasePage>
  );
};
