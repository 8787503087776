import React, { Component, MouseEvent } from 'react';
import './dashboard.scss';
import Logo from 'assets/images/logo_png.png';
import { map } from 'lodash';

import { Grid } from '@material-ui/core';
import moment from 'moment';
import {
  PerDayChartPoint,
  ToggleButtons,
  Logger,
  Card,
  BasePage,
  Select,
  EmptyState
} from 'morpheus-base-react';
import {
  CampanhaEstatisticasService,
  Estatisticas,
  DiaHistograma,
  EstatisticasPergunta,
  EstatisticasTodasPerguntas
} from 'service/campanha-estatisticas-service';
import DashboardResume from 'components/dashboard-resume/dashboard-resume';
import LeadsAndRejectionChart from 'components/leads-and-rejection-chart/leads-and-rejection-chart';
import AnswersTable, {
  AnswersTableProps
} from 'components/answers-table/answers-table';
import { CampanhaService, Campanha } from 'service/campanha-service';
import {
  CampanhaDetalheService,
  CampanhaDetalhe
} from 'service/campanha-detalhe-service';
import { LeadsTable } from 'components/leads-table/leads-table';
import {
  CampanhaExecucaoService,
  CampanhaExecucao
} from 'service/campanha-execucao-service';

import { Splash } from 'components/splash';
import { AuthContext, AuthData } from 'containers/auth-context';
import LoadingComponent from 'app/components/loading/loading';

interface DashboardProps {}
type Props = DashboardProps & { match: { params: any } };
type FiltroTempo = 'dia' | 'semana' | 'mes' | 'ano' | 'campanha';

export interface DashboardState {
  estatisticas?: Estatisticas;
  histograma?: DiaHistograma[];
  perguntas?: EstatisticasPergunta[];
  perguntasTotais?: EstatisticasTodasPerguntas;
  custoPorLead?: any;
  open?: boolean;
  timeFilter: FiltroTempo;
  campanha?: Campanha;
  campanhas: { label: string; value: number }[];
  campanhaDetalhe?: CampanhaDetalhe;
  campanhaSelecionada?: number;
  execucao?: CampanhaExecucao;
  perguntaComMenorEngajamento?: EstatisticasPergunta;
  perguntaComMaiorEngajamento?: EstatisticasPergunta;
  pathId?: any;
  loading: boolean;
  list_campaign: any[];
}

export class DashboardPage extends Component<Props, DashboardState> {
  private estatisticaService: CampanhaEstatisticasService;
  private campanhaService: CampanhaService;
  private campanhaExecucaoService: CampanhaExecucaoService;
  private campanhaDetalheService: CampanhaDetalheService;

  constructor(props: Readonly<Props>) {
    super(props);
    this.campanhaService = new CampanhaService();
    this.campanhaDetalheService = new CampanhaDetalheService();
    this.estatisticaService = new CampanhaEstatisticasService();
    this.campanhaExecucaoService = new CampanhaExecucaoService();
    this.state = {
      estatisticas: undefined,
      custoPorLead: {},
      open: false,
      timeFilter: 'campanha',
      campanhaSelecionada: undefined,
      campanhas: [],
      pathId: this.props.match.params.campanhaId,
      loading: true,
      list_campaign: []
    };
  }

  componentDidMount() {
    this.buscarCampanhas();
  }

  buscarCampanhas = () => {
    this.campanhaService
      .buscarCampanhas()
      .then(result => result.data)
      .then(campanhas => {
        this.setState({
          campanhas: map(campanhas.data, campanha => {
            if (campanha.campanha_detalhe) {
              return {
                label: campanha.nome,
                value: campanha.campanha_detalhe.id
              };
            }
          }),
          loading: false,
          list_campaign: campanhas.list_campaign
        });

        if (
          this.state.list_campaign.length > 0 &&
          this.state.list_campaign[0]
        ) {
          console.log('campanhas', this.state.campanhas);
          const selecionada = this.state.list_campaign[0];
          this.setState({ campanhaSelecionada: selecionada.value }, () => {
            this.fetchCampaignData(this.state.timeFilter);
          });
        }
      });
  };

  fetchCampaignData = (timeFilter: FiltroTempo) => {
    const duration = this.getDurationFromTimeFilter(timeFilter);
    const start = moment().subtract(duration).toDate();
    const end = new Date();

    this.campanhaExecucaoService
      .buscarCampanhaDetalhePorId(this.state.campanhaSelecionada)
      .then(result => result.data)
      .then(execucao => this.setState({ execucao }));

    this.campanhaService
      .buscarCampanhaPorIdCampanhaDetalhe(this.state.campanhaSelecionada)
      .then(result => result.data)
      .then(campanha => this.setState({ campanha, loading: false }));

    this.estatisticaService
      .buscarCustoPorLead(this.state.campanhaSelecionada, duration)
      .then(result => result.data)
      .then(custoPorLead => this.setState({ custoPorLead }));

    this.estatisticaService
      .buscarHistograma(this.state.campanhaSelecionada, start, end)
      .then(resposta => resposta.data)
      .then(histograma => this.setState({ histograma }));

    this.estatisticaService
      .buscarPerguntas(this.state.campanhaSelecionada, start, end)
      .then(resposta => resposta.data)
      .then(resultado => {
        const perguntas = resultado.por_pergunta;
        const perguntasOrdenadasPorEngajamento = [...perguntas].sort(
          (pergunta, outraPergunta) =>
            pergunta.estatisticas.quantidade_pessoas_responderam -
            outraPergunta.estatisticas.quantidade_pessoas_responderam
        );
        let perguntaComMenorEngajamento: any = null;
        let perguntaComMaiorEngajamento: any = null;
        if (perguntasOrdenadasPorEngajamento.length) {
          perguntaComMenorEngajamento = perguntasOrdenadasPorEngajamento[0];
          perguntaComMaiorEngajamento =
            perguntasOrdenadasPorEngajamento[
              perguntasOrdenadasPorEngajamento.length - 1
            ];
        }
        const perguntasTotais = resultado.totais;
        this.setState({
          perguntas,
          perguntasTotais,
          perguntaComMenorEngajamento,
          perguntaComMaiorEngajamento
        });
      });

    this.estatisticaService
      .buscarEstatisticas(this.state.campanhaSelecionada, start, end)
      .then(result => result.data)
      .then(estatisticas => this.setState({ estatisticas }));

    this.campanhaDetalheService
      .buscarCampanhaDetalhePorId(this.state.campanhaSelecionada)
      .then(result => result.data)
      .then(campanhaDetalhe => this.setState({ campanhaDetalhe }));
  };

  openDescricao = () => {
    this.setState(() => ({
      open: !this.state.open
    }));
  };

  onChangeFilter = (e: MouseEvent, timeFilter: FiltroTempo) => {
    Logger.debug('Filter changed to ', timeFilter);
    this.setState({ timeFilter, loading: true }, () => {
      this.fetchCampaignData(this.state.timeFilter);
    });
  };

  aoAlterarRelatorio = (evt: MouseEvent) => {
    this.setState(
      { campanhaSelecionada: evt.target.value, loading: true },
      () => {
        this.fetchCampaignData(this.state.timeFilter);
      }
    );
  };

  getDurationFromTimeFilter(timeFilter: FiltroTempo): moment.Duration {
    switch (timeFilter) {
      case 'dia':
        return moment.duration(1, 'day');
      case 'semana':
        return moment.duration(1, 'week');
      case 'mes':
        return moment.duration(1, 'month');
      case 'ano':
        return moment.duration(1, 'year');
      default:
        return moment.duration(10, 'year');
    }
  }

  getFilterButtons = () => {
    const { campanhaSelecionada } = this.state;
    return (
      <>
        {campanhaSelecionada && (
          <ToggleButtons
            value={this.state.timeFilter}
            onChange={this.onChangeFilter}
            buttons={[
              { value: 'campanha', label: 'Campanha' },
              { value: 'ano', label: 'Ano' },
              { value: 'mes', label: 'Mês' },
              { value: 'semana', label: 'Semana' },
              { value: 'dia', label: 'Dia' }
            ]}
          />
        )}
      </>
    );
  };

  getDate(date: any) {
    return !date ? undefined : new Date(date);
  }
  getLogo = () => {
    return <img src={Logo} width="54" />;
  };
  getValue = (obj, defaultValue, attribute?) => {
    if (!!attribute) {
      return !obj || !obj[attribute] ? defaultValue : obj[attribute];
    }
    return !obj ? defaultValue : obj;
  };

  answersTableProps: AnswersTableProps = () => {
    const {
      campanha,
      estatisticas,
      execucao,
      perguntaComMenorEngajamento,
      perguntaComMaiorEngajamento,
      perguntas,
      perguntasTotais
    } = this.state;
    return {
      answers: this.getValue(perguntas, []),
      campaignName: this.getValue(campanha, 'Indisponível', 'nome'),
      averageAnswerMiliseconds:
        estatisticas &&
        estatisticas.tempo_medio_entre_respostas_em_ms &&
        estatisticas.tempo_medio_entre_respostas_em_ms.no_periodo
          ? estatisticas.tempo_medio_entre_respostas_em_ms.no_periodo.asMilliseconds()
          : 0,
      conversionRate: (estatisticas && estatisticas.sucesso.percentual) || 0,
      validity: execucao && {
        start: new Date(execucao.dt_inicio),
        end: this.getDate(execucao.dt_fim)
      },
      leastEngagingQuestion: this.getValue(
        perguntaComMenorEngajamento,
        'Não determinado',
        'texto'
      ),
      mostEngagingQuestion:
        perguntaComMaiorEngajamento && perguntaComMaiorEngajamento.texto,
      answeredCount: this.getValue(perguntasTotais, 0, 'responderam'),
      notAnsweredCount: this.getValue(perguntasTotais, 0, 'nao_responderam')
    };
  };

  render() {
    const {
      campanhaSelecionada,
      campanhas,
      custoPorLead,
      estatisticas,
      histograma,
      timeFilter,
      loading,
      list_campaign
    } = this.state;

    const leadsPorDia: PerDayChartPoint[] = (histograma || []).map(dia => ({
      date: moment(dia.data, 'YYYY-MM-DD').toDate(),
      value: dia.finalizado,
      secondaryValue: dia.nao_iniciado + dia.parcial
    }));
    const duration = this.getDurationFromTimeFilter(timeFilter);
    const start = moment().subtract(duration).toDate();
    const end = new Date();
    return (
      <AuthContext.Consumer>
        {({ user }: AuthData) => (
          <div>
            {!user && (
              <Splash logo={this.getLogo()} backgroundColor={'#f0f4fd'} />
            )}
            {user && (
              <BasePage
                titlePage={
                  <Select
                    className="mph-select-relatorio"
                    label="Formulários"
                    variant="outlined"
                    options={list_campaign}
                    onChange={this.aoAlterarRelatorio}
                    value={campanhaSelecionada || ''}
                    disabled={!campanhas.length}
                  />
                }
                margin="26px"
                rightComponent={this.getFilterButtons()}
              >
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    {!campanhas.length && (
                      <EmptyState
                        title="TELA DE RESULTADOS"
                        subtitle={
                          'Você ainda não tem nenhum formulário cadastrado. Clique em "Novo Formulário" no menu para fazer o cadastro do seu primeiro formulário.'
                        }
                        titleVariant="big"
                        icon="CommentAltLines"
                      />
                    )}
                    {!campanhaSelecionada && campanhas.length && (
                      <>
                        <Grid container>
                          <DashboardResume
                            estatisticas={estatisticas}
                            custoPorLead={custoPorLead}
                          />
                        </Grid>
                        <Card title="Leads Cadastrados">
                          <LeadsAndRejectionChart data={leadsPorDia} />
                          <LeadsTable
                            campaignId={campanhas[0]}
                            start={start}
                            end={end}
                          />
                        </Card>
                        <AnswersTable {...this.answersTableProps()} />
                      </>
                    )}
                    {campanhaSelecionada && (
                      <>
                        <Grid container>
                          <DashboardResume
                            estatisticas={estatisticas}
                            custoPorLead={custoPorLead}
                          />
                        </Grid>
                        <Card title="Leads Cadastrados">
                          <LeadsAndRejectionChart data={leadsPorDia} />
                          <LeadsTable
                            campaignId={campanhaSelecionada}
                            start={start}
                            end={end}
                          />
                        </Card>
                        <AnswersTable {...this.answersTableProps()} />

                        <div
                          className="Copyright"
                          style={{ marginLeft: '10px' }}
                        >
                          Gump ©2019, Todos os direitos reservados
                          <span style={{ marginLeft: '82%' }}>V.1.1</span>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            height: '15px',
                            margin: '15px 117px 16px 0px'
                          }}
                        ></div>
                      </>
                    )}
                  </>
                )}
              </BasePage>
            )}
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}
