import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';

export interface CampanhaExecucao {
  id: number;
  campanha_id: number;
  dt_inicio: string;
  dt_fim?: string | null;
}

export class CampanhaExecucaoService {
  private static resource = '/campanhas/execucao';

  public async buscarCampanhaDetalhePorId(
    idCampanhaDetalhe: number
  ): Promise<AxiosResponse<CampanhaExecucao>> {
    return HttpClient.get(
      `${CampanhaExecucaoService.resource}/por-id-campanha-detalhe/${idCampanhaDetalhe}`
    );
  }

  public async patch(
    idCampanha: number,
    campanhaExecucao: Partial<CampanhaExecucao>
  ) {
    return HttpClient.patch(
      `${CampanhaExecucaoService.resource}/${idCampanha}`,
      campanhaExecucao
    );
  }
}
