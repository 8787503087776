import React from 'react';
import './splash.scss';

interface SplashProps {
  logo: any;
  backgroundColor: string;
}

const Splash = (props: SplashProps) => {
  return (
    <div className="splash" style={{ backgroundColor: props.backgroundColor }}>
      {props.logo}
    </div>
  );
};

export { Splash };
