import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import './modal-custom.scss';
import { Grid } from '@material-ui/core';

interface ModalCustomProps {
  open: boolean;
  title: string;
  isFecharDesabilitado?: boolean;
  handleClose?: () => void;
}

export default class ModalCustom extends Component<ModalCustomProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          disableBackdropClick={!!this.props.isFecharDesabilitado}
          disableEscapeKeyDown={!!this.props.isFecharDesabilitado}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(32, 57, 106, 0.24)'
            }
          }}
          onClose={() => this.props.handleClose && this.props.handleClose()}
        >
          <div
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              width: 400,
              backgroundColor: '#fff',
              boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.16)',
              padding: '16px',
              outline: 'none',
              borderRadius: '8px'
            }}
          >
            <Grid
              container
              justify="space-between"
              className="simple-modal-header"
            >
              <Grid item>
                <button
                  className="modal-custom_close-button"
                  onClick={() =>
                    this.props.handleClose && this.props.handleClose()
                  }
                ></button>
              </Grid>
            </Grid>

            <div className="simple-modal-header-line" />

            {this.props.children}
          </div>
        </Modal>
      </div>
    );
  }
}
