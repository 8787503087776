import React, { useEffect, useState } from 'react';
import './components.scss';

import { Button } from 'morpheus-base-react';
import _get from 'lodash/get';
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, convertToRaw } from 'draft-js';
import { fetchIcon } from '../fetchIcon';
import { styled } from '@material-ui/styles';

import {
  CampaignQuestionService,
  Question
} from '../../../../../../service/campaign-pergunta-service';

const Title = styled('h1')({
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  color: '#adbcd8',
  marginLeft: '6px'
});

const defaultTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    }
  }
});

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        backgroundColor: 'rgba(32, 57, 106, 0.04)'
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: '372px'
      },
      editor: {
        backgroundColor: 'rgba(32, 57, 106, 0.04)',
        padding: '20px',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 300px)'
      },
      toolbar: {
        borderBottom: '1px solid #d8dfec',
        backgroundColor: 'rgba(32, 57, 106, 0.04)'
      },
      placeHolder: {
        backgroundColor: 'rgba(32, 57, 106, 0.04)',
        paddingLeft: '20px',
        paddingTop: '25px',
        margin: '0px',
        position: 'initial'
      },
      anchorLink: {
        color: '#333333',
        textDecoration: 'underline'
      }
    }
  }
});

export const BaseText = ({ props, title, type, icon }) => {
  const fetchContent = () => {
    let content = _get(props.componentProps, 'formatting_settings', null);
    if (content === null)
      content = JSON.stringify({
        blocks: [
          {
            key: 'av1mm',
            text:
              props.componentProps && props.componentProps.texto
                ? props.componentProps.texto
                : '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      });
    return content;
  };

  const [text, setText] = useState('');
  const [content, setContent] = useState(fetchContent());
  const [editMode, setEditMode] = useState(false);
  const questionService = new CampaignQuestionService();

  useEffect(() => {
    if (props.componentProps) {
      setEditMode(true);
      setText(props.componentProps.texto);
    }
  }, [props.componentProps]);

  const onChange = (state: EditorState) => {
    setContent(JSON.stringify(convertToRaw(state.getCurrentContent())));
    setText(state.getCurrentContent().getPlainText());
  };

  const getQuestion = () => {
    const question = {
      campanha_id: props.campaignID,
      texto: text,
      formatting_settings: content,
      opcoes: [null],
      tipo: type,
      proxima_pergunta_id: null,
      final_fluxo: true,
      mensagem_inicial: false,
      variavel: null,
      mascara: null,
      variavel_integracao: null
    };
    return question;
  };

  const callback = (data: Question) => {
    const copyQuestions = [...props.questions];
    const newQuestion = data;
    const backQuestion = copyQuestions[props.componentID - 1];
    const remainingQuestions = backQuestion.proxima_pergunta_id;
    if (remainingQuestions) {
      newQuestion.final_fluxo = false;
      newQuestion.proxima_pergunta_id = remainingQuestions;
    }

    props.optionID > 0
      ? (backQuestion.opcoes[props.optionID - 1].proxima_pergunta_id =
          newQuestion.id)
      : (backQuestion.proxima_pergunta_id = newQuestion.id);
    newQuestion.opcoes = [null];
    newQuestion.variavel = newQuestion.id.toString();
    backQuestion.final_fluxo = false;
    copyQuestions.push(newQuestion);
    props.setQuestions(copyQuestions);
  };

  const sendQuestion = () => {
    const question = getQuestion();
    questionService
      .createQuestion(question)
      .then(result => result.data && callback(result.data));
  };

  const saveQuestion = () => {
    if (!editMode) {
      sendQuestion();
      onClose();
      return;
    }

    const copyQuestions = [...props.questions];
    copyQuestions[props.componentID - 1].texto = text;
    copyQuestions[props.componentID - 1].formatting_settings = content;
    props.setQuestions(copyQuestions);
    onBack();
  };

  const onBack = () => {
    props.setContentSideBar('DEFAULT');
    props.setComponentProps(null);
  };

  const onClose = () => {
    props.setComponentId(0);
    props.setOptionID(0);
  };

  return (
    <div className="containerConversationStep">
      <div className="contentConversationStep">
        <div className="headerConversationStep">
          <button onClick={onBack}>{fetchIcon('arrowLeft')}</button>
          {fetchIcon(icon)}
          <Title>{title}</Title>
        </div>
        <MuiThemeProvider theme={defaultTheme}>
          <MUIRichTextEditor
            controls={[
              'bold',
              'italic',
              'underline',
              'link',
              'media',
              'upload-image'
            ]}
            label={'Digite um texto...'}
            onChange={onChange}
            defaultValue={fetchContent()}
          />
        </MuiThemeProvider>
      </div>
      <div className="div-action-buttons">
        <div className="mph-action-buttons">
          <Button
            className="cancel"
            style={{
              backgroundColor: '#ffffff',
              color: '#004de8',
              margin: '8px 16px'
            }}
            onClick={() => onBack()}
          >
            Cancelar
          </Button>
          <Button
            className="insert"
            style={{ margin: '8px 16px' }}
            onClick={() => saveQuestion()}
          >
            Inserir
          </Button>
        </div>
      </div>
    </div>
  );
};
