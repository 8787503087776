import React, { useState, useEffect } from 'react';
import './components.scss';

import _get from 'lodash/get';
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, convertToRaw } from 'draft-js';
import { fetchIcon } from '../fetchIcon';
import { styled } from '@material-ui/styles';
import { Radio, TextField, Button } from 'morpheus-base-react';
import { Modal } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import IconAdd from 'svg-react-loader!assets/images/plus-circle-green.svg';
import IconRemove from 'svg-react-loader!assets/images/times-circle-red.svg';

import {
  CampaignQuestionService,
  Question
} from '../../../../../../service/campaign-pergunta-service';

interface optionsType {
  chave?: string;
  valor?: string;
  ordem?: number;
  classe_css?: any;
  proxima_pergunta_id?: any;
  campanha_pergunta_id?: number | null;
}

const Title = styled('h1')({
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  color: '#adbcd8',
  marginLeft: '6px'
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      padding: 24,
      outline: 'none',
      borderRadius: '8px',
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.16)'
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cancelButton: {
      marginRight: '12px',
      color: '#62708b'
    },
    removeButton: {
      color: '#ffffff',
      borderColor: '#ff003a',
      backgroundColor: '#ff003a'
    }
  })
);

const defaultTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    }
  }
});

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        backgroundColor: 'rgba(32, 57, 106, 0.04)'
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        width: '372px'
      },
      editor: {
        backgroundColor: 'rgba(32, 57, 106, 0.04)',
        padding: '20px',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 300px)'
      },
      toolbar: {
        borderBottom: '1px solid #d8dfec',
        backgroundColor: 'rgba(32, 57, 106, 0.04)'
      },
      placeHolder: {
        backgroundColor: 'rgba(32, 57, 106, 0.04)',
        paddingLeft: '20px',
        paddingTop: '25px',
        margin: '0px',
        position: 'initial'
      },
      anchorLink: {
        color: '#333333',
        textDecoration: 'underline'
      }
    }
  }
});

export const BaseSelect = ({ props, title, type, icon }) => {
  const fetchContent = () => {
    let content = _get(props.componentProps, 'formatting_settings', null);
    if (content === null)
      content = JSON.stringify({
        blocks: [
          {
            key: 'av1mm',
            text:
              props.componentProps && props.componentProps.texto
                ? props.componentProps.texto
                : '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      });
    return content;
  };

  const [newType, setNewType] = useState(type);
  const [text, setText] = useState('');
  const [content, setContent] = useState(fetchContent);
  const [editMode, setEditMode] = useState(false);
  const questionService = new CampaignQuestionService();
  const [options, setOptions] = useState<optionsType[]>([
    {
      chave: '',
      valor: '',
      ordem: 0,
      classe_css: null,
      proxima_pergunta_id: null,
      campanha_pergunta_id: null
    }
  ]);
  const classes = useStyles();
  const [openModalDelete, setOpenModalDelete] = useState(0);

  useEffect(() => {
    if (props.componentProps) {
      setEditMode(true);
      setText(props.componentProps.texto);
      setOptions(props.componentProps.opcoes);
    }
  }, [props.componentProps]);

  const onChange = (state: EditorState) => {
    setContent(JSON.stringify(convertToRaw(state.getCurrentContent())));
    setText(state.getCurrentContent().getPlainText());
  };

  const getQuestion = () => {
    const question = {
      campanha_id: props.campaignID,
      texto: text,
      formatting_settings: content,
      tipo: newType,
      proxima_pergunta_id: null,
      final_fluxo: true,
      mensagem_inicial: false,
      variavel: null,
      mascara: null,
      variavel_integracao: null
    };
    return question;
  };

  const callback = (data: Question) => {
    const copyQuestions = [...props.questions];
    const newQuestion = data;
    const backQuestion = copyQuestions[props.componentID - 1];
    const remainingQuestions = backQuestion.proxima_pergunta_id;
    if (remainingQuestions) {
      newQuestion.final_fluxo = false;
      newQuestion.proxima_pergunta_id = remainingQuestions;
    }

    props.optionID > 0
      ? (backQuestion.opcoes[props.optionID - 1].proxima_pergunta_id =
          newQuestion.id)
      : (backQuestion.proxima_pergunta_id = newQuestion.id);
    backQuestion.final_fluxo = false;
    newQuestion.variavel = newQuestion.id.toString();

    if (options.length) {
      const getNewOptions = async () => {
        return Promise.all(
          options.map(async opt => {
            opt.campanha_pergunta_id = newQuestion.id;
            return await (await questionService.createOption(opt)).data;
          })
        );
      };

      getNewOptions().then(data => {
        newQuestion.opcoes = data;
        copyQuestions.push(newQuestion);
        props.setQuestions(copyQuestions);
      });
    } else {
      newQuestion.opcoes = [null];
      copyQuestions.push(newQuestion);
      props.setQuestions(copyQuestions);
    }
  };

  const sendQuestion = () => {
    const question = getQuestion();
    questionService
      .createQuestion(question)
      .then(result => result.data && callback(result.data));
  };

  const saveQuestion = () => {
    if (!editMode) {
      sendQuestion();
      onClose();
      return;
    }

    const copyQuestions = [...props.questions];
    copyQuestions[props.componentID - 1].texto = text;
    copyQuestions[props.componentID - 1].formatting_settings = content;
    copyQuestions[props.componentID - 1].opcoes = options;
    copyQuestions[props.componentID - 1].tipo = newType;
    props.setQuestions(copyQuestions);
    onBack();
  };

  const onBack = () => {
    props.setContentSideBar('DEFAULT');
    props.setComponentProps(null);
  };

  const onClose = () => {
    props.setComponentId(0);
    props.setOptionID(0);
  };

  const onAdd = () => {
    const newOptions = [...options];
    newOptions.push({
      classe_css: null,
      proxima_pergunta_id: null
    });
    setOptions(newOptions);
  };

  const onRemove = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const onChangeOptions = (value: string, index: number) => {
    const newOptions = [...options];
    newOptions[index].chave = value;
    newOptions[index].valor = value;
    newOptions[index].ordem = index + 1;
    setOptions(newOptions);
  };

  return (
    <div className="containerConversationStep">
      <div className="contentConversationStep">
        <div className="headerConversationStep">
          <button onClick={onBack}>{fetchIcon('arrowLeft')}</button>
          {fetchIcon(icon)}
          <Title>{title}</Title>
        </div>

        <Modal
          className={classes.modal}
          open={!!openModalDelete}
          onClose={() => setOpenModalDelete(0)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            {fetchIcon('trashModal')}
            <h2
              style={{
                color: '#20396a',
                fontSize: '18px',
                font: 'Source Sans Pro',
                fontWeight: 'bold'
              }}
            >
              Deseja mesmo excluir o componente?
            </h2>
            <p
              style={{
                color: '#62708b',
                fontSize: '16px',
                font: 'Source Sans Pro'
              }}
            >
              Esta opção será excluída junto com os outros componentes de seu
              fluxo. Após você excluir os mesmos não poderão ser recuperados.
              Deseja continuar?
            </p>
            <div style={{ display: 'flex', float: 'right', marginTop: '24px' }}>
              <Button
                className={classes.cancelButton}
                size="small"
                variant="text"
                onClick={() => setOpenModalDelete(0)}
              >
                <span>Cancelar</span>
              </Button>
              <Button
                className={classes.removeButton}
                variant="outlined"
                size="small"
                color="inverse"
                onClick={() => {
                  onRemove(openModalDelete - 1);
                  setOpenModalDelete(0);
                }}
              >
                <span>Excluir</span>
              </Button>
            </div>
          </div>
        </Modal>

        {newType !== 'CHECKBOX' && (
          <div>
            <Radio
              name="typeSelect"
              color={'primary'}
              value={newType}
              onChange={(el: any) => setNewType(el.target.value)}
              options={[
                {
                  label: 'Botão',
                  value: 'BUTTON'
                },
                {
                  label: 'Caixa de seleção',
                  value: 'SELECT'
                },
                {
                  label: 'Radio',
                  value: 'RADIO'
                }
              ]}
            />
          </div>
        )}
        <MuiThemeProvider theme={defaultTheme}>
          <MUIRichTextEditor
            controls={[
              'bold',
              'italic',
              'underline',
              'link',
              'media',
              'upload-image'
            ]}
            label={'Digite um texto...'}
            onChange={onChange}
            defaultValue={fetchContent()}
          />
        </MuiThemeProvider>
        <div className="selectOptions">
          <p style={{ fontFamily: 'Source Sans Pro' }}>Opções</p>
          {options.map((item, index) => (
            <div key={index} className="selectOption">
              <TextField
                id="standard-username"
                label={`Opção ${index + 1}`}
                type="text"
                variant="outlined"
                defaultValue={item.valor}
                onChange={e => onChangeOptions(e.target.value, index)}
              />
              <div className="selectActions">
                {options.length !== 1 && index != 0 ? (
                  <IconRemove
                    className="rm"
                    onClick={() => setOpenModalDelete(index + 1)}
                  />
                ) : (
                  <div></div>
                )}
                <IconAdd className="add" onClick={onAdd} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="div-action-buttons">
        <div className="mph-action-buttons">
          <Button
            className="cancel"
            style={{
              backgroundColor: '#ffffff',
              color: '#004de8',
              margin: '8px 16px'
            }}
            onClick={() => onBack()}
          >
            Cancelar
          </Button>
          <Button
            className="insert"
            style={{ margin: '8px 16px' }}
            onClick={() => saveQuestion()}
          >
            Inserir
          </Button>
        </div>
      </div>
    </div>
  );
};
