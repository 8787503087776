import React from 'react';
import { BaseText } from './baseText';

export const Email = ({
  campaignID,
  componentID,
  componentProps,
  setComponentProps,
  setComponentId,
  questions,
  setQuestions,
  setContentSideBar,
  optionID,
  setOptionID
}) => {
  return (
    <BaseText
      props={{
        campaignID,
        componentID,
        componentProps,
        setComponentProps,
        setComponentId,
        questions,
        setQuestions,
        setContentSideBar,
        optionID,
        setOptionID
      }}
      title="E-mail"
      type="MAIL"
      icon={'email'}
    />
  );
};
