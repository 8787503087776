import React from 'react';
import { Switch } from 'morpheus-base-react';

interface Props {
  closeButton: boolean;
  setCloseButton: () => void;
}

export const AdvancedSettings = ({ setCloseButton, closeButton }) => {
  return (
    <div className="mph-custom-adv-settings">
      <p className="mph-title">Configurações da apresentação de formulário.</p>
      <Switch
        onChange={() => setCloseButton(!closeButton)}
        checked={closeButton}
      >
        Possuir botão de fechar o chat?
      </Switch>
    </div>
  );
};
