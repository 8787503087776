import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';
import { CampanhaDetalhe } from './campanha-detalhe-service';
import { CampanhaExecucao } from './campanha-execucao-service';

export interface Campanha {
  cliente_id: number;
  descricao: string;
  dt_criacao: Date;
  criado_por?: string;
  hash: string;
  id: number;
  nome: string;
  status: string;
  tag_url: string;
  tempo_sessao: number;
  tipo: string;
}

export interface CampanhaCompleta extends Campanha {
  detalhe: CampanhaDetalhe;
  execucao: CampanhaExecucao;
}

export interface CampanhaPaginada {
  data: [];
  params: {
    count: number;
    page: number;
    totalPages: number;
    rowsPerPage: number;
  };
  list_campaign: {
    label: string;
    value: number;
  };
}

export interface DetalheCampanha {
  nome: string;
  descricao: string;
  dataCriacao: Date;
  criado: string;
  status: string;
}

export class CampanhaService {
  private static resource = '/campanha';

  public async buscarCampanha(
    idCampanha: number
  ): Promise<AxiosResponse<CampanhaCompleta>> {
    return HttpClient.get(`${CampanhaService.resource}/${idCampanha}`);
  }

  public async patch(
    idCampanha: number,
    atributos: Partial<Campanha>
  ): Promise<AxiosResponse<void>> {
    return HttpClient.patch(
      `${CampanhaService.resource}/${idCampanha}`,
      atributos
    );
  }

  public async delete(idCampanha: number): Promise<AxiosResponse<void>> {
    return HttpClient.post(`${CampanhaService.resource}/remove/${idCampanha}`);
  }

  public async buscarCampanhaPorIdCampanhaDetalhe(
    idCampanhaDetalhe: number
  ): Promise<AxiosResponse<Campanha>> {
    return HttpClient.get(
      `${CampanhaService.resource}/por-id-campanha-detalhe/${idCampanhaDetalhe}`
    );
  }

  public async buscarCampanhas(
    page: number,
    size: number
  ): Promise<AxiosResponse<CampanhaPaginada>> {
    return HttpClient.get(`${CampanhaService.resource}/cliente`, {
      params: {
        page,
        size
      }
    });
  }

  public async buscarDetalheCampanhas(): Promise<
    AxiosResponse<DetalheCampanha[]>
  > {
    return HttpClient.get(`${CampanhaService.resource}/`);
  }
}
