import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';
import { BASE_URL } from 'service/http-client';

export interface Access {
  impersonate: boolean;
  manage: boolean;
  manageGroupMembership: boolean;
  mapRoles: boolean;
  view: boolean;
}

export interface Role {
  clientRole: boolean;
  composite: boolean;
  containerId: string;
  id: string;
  name: string;
}

export interface Usuario {
  access: Access;
  createdTimestamp: number;
  disableableCredentialTypes: string[];
  email?: string;
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  id: string;
  lastName: string;
  notBefore: number;
  requiredActions: any[];
  roles: Role[];
  totp: boolean;
  username: string;
  groups: any[];
}

export interface User {
  aud: string;
  auth_time: number;
  email: string;
  email_verified: boolean;
  exp: number;
  firebase: any;
  iat: number;
  iss: string;
  name: string;
  picture: string;
  sub: string;
  uid: string;
  user_id: string;
}

export interface CriacaoUsuario {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
}

export type RolesUpdate = string[];

export class UsuarioService {
  private static resource = `${BASE_URL}usuarios/`;

  public async getUser(): Promise<AxiosResponse<User>> {
    return HttpClient.get(UsuarioService.resource);
  }

  public async updateUser(usuario: Usuario): Promise<AxiosResponse<Usuario>> {
    return HttpClient.put(UsuarioService.resource, usuario);
  }

  public async confirm(email: string): Promise<AxiosResponse<void>> {
    return HttpClient.post(UsuarioService.resource, { email: email });
  }

  public async deleteUser(): Promise<AxiosResponse<void>> {
    return HttpClient.delete(UsuarioService.resource);
  }
}
