import React, { ReactNode } from 'react';
import { Switch, Route } from 'react-router-dom';
import { DashboardPage } from './dashboard/dashboard';
import { Usuario } from './usuario/usuario';
import PesquisarCampanha from './campanha/pesquisar-campanha/pesquisar-campanha';
import { NovoFormulario } from './campanha/novo-formulario/novo-formulario';
import { EditarFormulario } from './campanha/novo-formulario/editar-formulario';
import { Error404 } from './error/error404';
import { Logout } from './logout/logout';

const Routes = ({ user }): ReactNode => (
  <Switch>
    <Route path="/" exact component={DashboardPage} />
    <Route path="/visualizar/:campanhaId" exact component={DashboardPage} />
    <Route path="/usuario">
      <Usuario u={user}></Usuario>
    </Route>
    <Route path="/formularios" exact component={PesquisarCampanha} />
    <Route path="/novo-formulario" exact component={NovoFormulario} />
    <Route
      path="/editar-formulario/:campanhaId"
      exact
      component={EditarFormulario}
    />
    <Route path="/pagina-nao-encontrada" exact component={Error404} />
    <Route path="/logout" exact component={Logout} />
  </Switch>
);

export default Routes;
