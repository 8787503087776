import React from 'react';
import Chat from 'svg-react-loader!assets/images/small-chat.svg';
import Checkmark from 'svg-react-loader!assets/images/small-checkmark.svg';
import Email from 'svg-react-loader!assets/images/small-email.svg';
import Message from 'svg-react-loader!assets/images/small-message.svg';
import Picture from 'svg-react-loader!assets/images/small-picture.svg';
import PlusIcon from 'svg-react-loader!assets/images/small-plus-icon.svg';
import Radio from 'svg-react-loader!assets/images/small-radio.svg';
import Smile from 'svg-react-loader!assets/images/small-smile.svg';
import Star from 'svg-react-loader!assets/images/small-star.svg';
import Wink from 'svg-react-loader!assets/images/small-wink.svg';
import Cog from 'svg-react-loader!assets/images/small-cog.svg';
import Duplicate from 'svg-react-loader!assets/images/small-copy.svg';
import Flow from 'svg-react-loader!assets/images/small-flow.svg';
import Trash from 'svg-react-loader!assets/images/small-trash.svg';
import Draggable from 'svg-react-loader!assets/images/draggable-icon.svg';
import TrashBig from 'svg-react-loader!assets/images/trash-alt.svg';
import Close from 'svg-react-loader!assets/images/close.svg';
import ArrowLeft from 'svg-react-loader!assets/images/arrow-left-2.svg';
import Edit from 'svg-react-loader!assets/images/edit.svg';

// eslint-disable-next-line complexity
export const fetchIcon = (iconName: string) => {
  switch (iconName) {
    case 'chat':
      return <Chat />;
    case 'checkmark':
      return <Checkmark />;
    case 'email':
      return <Email />;
    case 'message':
      return <Message />;
    case 'picture':
      return <Picture />;
    case 'plusicon':
      return <PlusIcon />;
    case 'radio':
      return <Radio />;
    case 'smile':
      return <Smile />;
    case 'star':
      return <Star />;
    case 'wink':
      return <Wink />;
    case 'cog':
      return <Cog />;
    case 'duplicate':
      return <Duplicate />;
    case 'flow':
      return <Flow />;
    case 'trash':
      return <Trash />;
    case 'draggable':
      return <Draggable />;
    case 'trashModal':
      return <TrashBig />;
    case 'close':
      return <Close />;
    case 'arrowLeft':
      return <ArrowLeft />;
    case 'edit':
      return <Edit />;
    default:
      return;
  }
};
