import React from 'react';
import { styled } from '@material-ui/styles';
import { Avatar, IconButton, Button } from 'morpheus-base-react';

interface CustomImageBackgroundProps {
  avatarImg: string;
  onClick: () => void;
  onRemove: () => void;
}

const SizeDisclaimer = styled('p')({
  fontFamily: 'Source Sans Pro',
  fontSize: '12px',
  color: '#62708b',
  textAlign: 'center',
  margin: '0px'
});

export const CustomImageBackground = ({
  avatarImg,
  onClick,
  onRemove
}: CustomImageBackgroundProps) => {
  return (
    <div>
      Selecione a imagem de fundo para o seu template:
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
          marginTop: '10px',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div>
          <Avatar src={avatarImg} size="64px">
            logo
          </Avatar>
          <a
            onClick={onClick}
            style={{
              position: 'relative',
              bottom: '15px',
              left: '19px'
            }}
          >
            <IconButton
              size={24}
              backgroundColor="primary"
              icon="PlusAdd"
              iconStyle={{ position: 'absolute', top: '4px' }}
            />
          </a>
        </div>
        <Button variant="text" onClick={onRemove}>
          Remover
        </Button>
      </div>
      <SizeDisclaimer>Resolução recomendada de 1920x1080 px.</SizeDisclaimer>
    </div>
  );
};
