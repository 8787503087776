import { createSelector } from 'reselect';

import { State } from '../type';

import { UserState } from './reducer';

export function selectUserState(state: State): UserState {
  return state.user;
}
export const selectUser = createSelector(selectUserState, state => state.user);

export function errorUserState(state: State): UserState {
  return state.user;
}

export const errorUser = createSelector(errorUserState, state => state.error);
