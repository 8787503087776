import { SideMenuItemType } from 'morpheus-base-react';

export const menuItems: SideMenuItemType[] = [
  {
    key: '1',
    path: '/',
    title: 'Resultados',
    icone: 'DashboardPartial'
  },
  {
    key: '2',
    path: '/formularios',
    title: 'Formulários',
    icone: 'Dashboard'
  },
  {
    key: '3',
    path: '/novo-formulario',
    title: 'Novo Formulário',
    icone: 'Building'
  }
];
