import React from 'react';
import GumpMascot from 'svg-react-loader!assets/images/gump-mascot.svg';
import { NavigationButtons } from '../NavigationButtons';
import { styled } from '@material-ui/styles';

interface StartingStepProps {
  forwardAction: () => void;
  campanhaId: number | undefined;
}

const Wrapper = styled('div')({
  height: 'calc(100% - 66px)'
});

const Title = styled('h1')({
  color: '#62708b',
  fontSize: '24px',
  fontWeight: 600,
  margin: '8px',
  marginTop: '32px',
  textAlign: 'center',
  fontFamily: 'Source Sans Pro'
});

const Subtitle = styled('p')({
  color: '#62708b',
  fontSize: '16px',
  margin: 0,
  marginBottom: '50px',
  fontFamily: 'Source Sans Pro'
});

const Content = styled('div')({
  minHeight: '100%',
  height: 'auto !important',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});

const Footer = styled('footer')({
  height: '66px',
  width: '100%'
});

export const StartingStep = ({
  forwardAction,
  campanhaId
}: StartingStepProps) => {
  return (
    <>
      <Wrapper>
        <Content>
          <GumpMascot />
          <Title>Bem-vindo ao Gump.FORM</Title>
          <Subtitle>
            {campanhaId
              ? 'Clique em AVANÇAR para editar o seu formulário.'
              : 'Clique em AVANÇAR para iniciar a criação de um novo formulário.'}
          </Subtitle>
        </Content>
      </Wrapper>
      <Footer>
        <NavigationButtons forwardAction={() => forwardAction()} />
      </Footer>
    </>
  );
};
