import { ActionsUnion, DispatchAction } from '../type';
import { createAction } from '../action';
import { Campaign } from './type';
import { CampaignService } from 'service/campaign-service';

const campaignService = new CampaignService();

export enum ActionTypes {
  GetCampaign = 'GetCampaign',
  CreateCampaign = 'CreateCampaign',
  UpdateCampaign = 'UpdateCampaign',
  ClearCampaign = 'ClearCampaign'
}

export interface CampaignOptions {
  campaign: Campaign;
}

export const Actions = {
  getCampaign: (options: CampaignOptions) =>
    createAction(ActionTypes.GetCampaign, options)
};

export type Actions = ActionsUnion<typeof Actions>;

export function getCampaign(id): DispatchAction {
  return async dispatch => {
    const response = await campaignService.fetchCampaign(id);
    dispatch(Actions.getCampaign({ campaign: response.data }));
  };
}

export function createCampaign(nome, descricao, tipo): DispatchAction {
  return async dispatch => {
    const response = await campaignService.createCampaign(
      nome,
      descricao,
      tipo
    );
    dispatch(Actions.getCampaign({ campaign: response.data }));
  };
}

export function updateCampaign(id, fields): DispatchAction {
  return async dispatch => {
    const response = await campaignService.updateCampaign(id, fields);
    dispatch(Actions.getCampaign({ campaign: response.data }));
  };
}

export function clearCampaign(): DispatchAction {
  return async dispatch => {
    const response = await campaignService.clearCampaign();
    dispatch(Actions.getCampaign({ campaign: response.data }));
  };
}
