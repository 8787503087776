import React from 'react';
import { styled } from '@material-ui/styles';

const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(32, 57, 106, 0.24)'
});

const Button = styled('button')({
  fontSize: '16px',
  border: 0,
  padding: '16px 24px',
  backgroundColor: '#FFF',
  cursor: 'pointer',
  fontFamily: 'Source Sans Pro'
});

const TabItem = ({ isActive, content, onClick }) => {
  const currentColor = isActive ? '#004de8' : '#62708b';
  const currentBorder = isActive
    ? '2px solid #004de8'
    : '2px solid transparent';
  const currentFontWeight = isActive ? 600 : 500;
  return (
    <Button
      onClick={() => onClick()}
      style={{
        color: currentColor,
        borderBottom: currentBorder,
        fontWeight: currentFontWeight
      }}
    >
      {content}
    </Button>
  );
};

export const TabMenu = ({ onClick, currentlyActive }) => {
  return (
    <Wrapper>
      <div>
        <TabItem
          isActive={currentlyActive === 1}
          content="Imagem"
          onClick={() => onClick(1)}
        />
        <TabItem
          isActive={currentlyActive === 2}
          content="GIPHY"
          onClick={() => onClick(2)}
        />
      </div>
    </Wrapper>
  );
};
