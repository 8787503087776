import React, { Component } from 'react';

import { InformationCard } from 'morpheus-base-react';
import { Grid } from '@material-ui/core';
import { Estatisticas } from 'service/campanha-estatisticas-service';

export interface DashboardProps {
  estatisticas?: Estatisticas;
  custoPorLead?: any;
}

export default class DashboardResume extends Component<DashboardProps> {
  getCustoPorLead() {
    return this.props.custoPorLead && this.props.custoPorLead.periodo;
  }

  getVariacaoDoCustoPorLead() {
    return (
      this.props.custoPorLead && this.props.custoPorLead.variacao_percentual
    );
  }

  getConversao() {
    return (
      this.props.estatisticas && this.props.estatisticas.sucesso.quantidade
    );
  }

  getConversaoPercentual() {
    return (
      this.props.estatisticas &&
      this.props.estatisticas.sucesso.quantidade &&
      this.props.estatisticas.sucesso.percentual
    );
  }

  getVariacaoConversao() {
    return (
      this.props.estatisticas &&
      this.props.estatisticas.sucesso.variacao_percentual_do_periodo
    );
  }

  getVisitantes() {
    return (
      this.props.estatisticas && this.props.estatisticas.acessos.quantidade
    );
  }

  getVariacaoVisitantes() {
    return (
      this.props.estatisticas &&
      this.props.estatisticas.acessos.variacao_percentual_do_periodo
    );
  }

  getAbandonos() {
    return (
      this.props.estatisticas && this.props.estatisticas.parciais.quantidade
    );
  }

  getVariacaoAbandonos() {
    return (
      this.props.estatisticas &&
      this.props.estatisticas.parciais.variacao_percentual_do_periodo
    );
  }

  getTempoMedioRespostas() {
    return (
      this.props.estatisticas &&
      this.props.estatisticas.tempo_medio_entre_respostas_em_ms &&
      this.props.estatisticas.tempo_medio_entre_respostas_em_ms.no_periodo
    );
  }

  getVariacaoTempoMedioRespostas() {
    return (
      this.props.estatisticas &&
      this.props.estatisticas.tempo_medio_entre_respostas_em_ms &&
      this.props.estatisticas.tempo_medio_entre_respostas_em_ms
        .variacao_percentual_do_periodo
    );
  }

  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <InformationCard
              color="purple"
              icon="Users"
              value={this.getVisitantes()}
              valueType="number"
              percent={this.getVariacaoVisitantes()}
              description="Acessos na página"
              title="VISITANTES"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <InformationCard
              color="green"
              icon="UserCheck"
              value={this.getConversaoPercentual()}
              valueType="percentage"
              percent={this.getVariacaoConversao()}
              description="Visitantes se cadastraram na página"
              title="CONVERSÃO"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <InformationCard
              color="blue"
              icon="UserCheck"
              value={this.getConversao()}
              valueType="number"
              percent={this.getVariacaoConversao()}
              description="Visitantes se cadastraram na página"
              title="SUCESSO"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <InformationCard
              color="red"
              icon="UserExclamation"
              value={this.getAbandonos()}
              title="ABANDONO"
              percent={this.getVariacaoAbandonos()}
              valueType="number"
              description="Visitantes não preencheram todo o formulário"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <InformationCard
              color="blueGrey"
              icon="UserCheck"
              value={this.getTempoMedioRespostas()}
              title="TEMPO MÉDIO"
              percent={this.getVariacaoTempoMedioRespostas()}
              valueType="duration_ms_in_seconds"
              description="Tempo médio de respostas"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <InformationCard
              color="orange"
              icon="UserCheck"
              value={this.getCustoPorLead()}
              title="CPL (Custo por Lead)"
              percent={this.getVariacaoDoCustoPorLead()}
              valueType="money"
              description="Investimento médio com cada lead"
              invertPercentageIndicatorColor
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
