import React, { useState, useEffect } from 'react';

import GumpLogo from 'svg-react-loader!assets/images/gump.svg';
import MorpheusLogo from 'svg-react-loader!assets/images/morpheus.svg';
import LoginBG from 'assets/images/login_bg.jpg';
import './login.scss';
import { useMediaQuery } from '@material-ui/core';
import { UsuarioService } from '../../service/usuario-service';
import {
  Button as BaseButton,
  SnackbarServiceInstance
} from 'morpheus-base-react';
import { styled } from '@material-ui/styles';

const Button = styled(BaseButton)({
  margin: '8px 16px',
  padding: '9px 16px 11px 24px',
  borderRadius: '100px',
  border: '1px solid #004de8',
  fontSize: '16px',
  color: '#004de8',
  '&:focus': {
    backgroundColor: '#004de8',
    color: '#fff'
  },
  '& span': {
    marginRight: '8px'
  }
});

export interface LoginProps {
  email: string;
}

export const Login = ({ email }: LoginProps) => {
  const noMobile = useMediaQuery('(min-width:600px)');
  const [flag, setFlag] = useState(false);

  const googleWidget = () => {
    return (
      <>
        <div className="login-text">Faça seu login!</div>
        <div id="firebaseui-auth-container"></div>
        <div className="login-register">Ainda não tem uma conta?</div>
        <div className="login-register">
          Se cadastre escolhendo uma das opções acima!
        </div>
      </>
    );
  };

  const sendLink = () => {
    const userService = new UsuarioService();
    userService.confirm(email).then(() => {
      console.log('mensagem de link enviado!');
      SnackbarServiceInstance.showMessage({
        message: 'Link de verificação enviado!',
        variant: 'success'
      });
    });
  };
  const click = () => {
    setFlag(false);
    document.dispatchEvent(new CustomEvent('logout'));
    window.location.reload(true);
  };

  useEffect(() => {
    if (email != '') {
      setFlag(true);
    }
  }, [email]);

  const emailVerifyWidget = () => {
    return (
      <>
        <div className="login-text">{`Enviamos um e-mail para ${email}`}</div>
        <div className="login-register">
          Verifique sua caixa de e-mail para ativar sua conta.
        </div>
        <Button variant="text" onClick={() => sendLink()}>
          <span>Enviar novamente</span>
        </Button>
        <a className="inputImport" onClick={() => click()}>
          Voltar para Login
        </a>
      </>
    );
  };
  return (
    <div className="login-wrapper">
      {noMobile && (
        <div className="login-left">
          <img src={LoginBG} className="login-bgimage" />
        </div>
      )}
      <div className={noMobile ? 'login-right' : 'login-right-mobile'}>
        <div className="login-logo">
          <GumpLogo />
        </div>
        {flag && emailVerifyWidget()}
        {!flag && googleWidget()}
        <div className="login-created-by">
          Criado por <MorpheusLogo className="login-mphlogo" />
        </div>
      </div>
    </div>
  );
};
