import { duration } from 'moment';
import * as evanHumanizeDuration from 'humanize-duration';
import { formatDecimal } from './number-formatter';

export function formatSeconds(milliseconds: number): number {
  return formatDecimal(duration(milliseconds, 'milliseconds').asSeconds(), 1);
}

export function humanizeDuration(milliseconds: number): string {
  return evanHumanizeDuration(milliseconds, { language: 'pt', round: true });
}

export function formatDate(date: Date): string {
  if (!date) {
    return 'Indefinido';
  }
  const tzOffset = date.getTimezoneOffset() * 60_000 * -1;
  const newDate = new Date(date.getTime() - tzOffset);
  return newDate.toLocaleDateString();
}
