import { createSelector } from 'reselect';

import { State } from '../type';

import { CampaignState } from './reducer';

export function selectCampaignState(state: State): CampaignState {
  return state.campaign;
}

export const selectCampaign = createSelector(
  selectCampaignState,
  state => state.campaign
);
