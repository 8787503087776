import { Actions } from '../type';
import { Campaign } from './type';

import { ActionTypes } from './action';

export interface CampaignState {
  campaign?: Campaign;
}
const initialState: Readonly<CampaignState> = {};

export function reducer(
  state: CampaignState = initialState,
  action: Actions
): CampaignState {
  switch (action.type) {
    case ActionTypes.GetCampaign ||
      ActionTypes.CreateCampaign ||
      ActionTypes.UpdateCampaign:
      const { campaign } = action.payload;
      return { campaign };
    case ActionTypes.ClearCampaign:
      return {};
    default:
      return state;
  }
}
