import React from 'react';
import { TextField, Select } from 'morpheus-base-react';

const fontFamilyOptions = [
  {
    label: 'Open Sans',
    value: 'open Sans'
  },
  {
    label: 'Source Sans Pro',
    value: 'source sans pro'
  },
  {
    label: 'Sans Serif',
    value: 'sans-serif'
  },
  {
    label: 'Arial',
    value: 'Arial'
  }
];

const titleFontSizeOptions = [
  {
    label: '24',
    value: 24
  },
  {
    label: '32',
    value: 32
  },
  {
    label: '40',
    value: 40
  }
];

const textFontSizeOptions = [
  {
    label: '16',
    value: 16
  },
  {
    label: '24',
    value: 24
  },
  {
    label: '32',
    value: 32
  }
];

export const CustomText = ({
  setTitle,
  setText,
  title,
  text,
  setTitleFontFamily,
  setTitleFontSize,
  setTextFontFamily,
  setTextFontSize,
  defaultTitleFontFamily = fontFamilyOptions[0].value,
  defaultTitleFontSize = titleFontSizeOptions[2].value,
  defaultTextFontFamily = fontFamilyOptions[0].value,
  defaultTextFontSize = textFontSizeOptions[2].value
}) => {
  return (
    <div>
      {/* <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore.
      </p> */}
      Títulos
      <div>
        <TextField
          type={'text'}
          label={'Digite o título'}
          variant={'filled'}
          defaultValue={title}
          onChange={value => setTitle(value.target.value)}
          fullWidth
        />
      </div>
      <div className="mph-custom-text-wrapper">
        <p className="mph-title">Escolha a fonte do título para o template.</p>
        <Select
          className="mph-select"
          defaultValue={defaultTitleFontFamily}
          label="Fonte"
          variant="outlined"
          options={fontFamilyOptions}
          onChange={el => setTitleFontFamily(el.target.value)}
        />
        <Select
          className="mph-select"
          defaultValue={defaultTitleFontSize}
          label="Tamanho"
          variant="outlined"
          options={titleFontSizeOptions}
          onChange={el => setTitleFontSize(el.target.value)}
        />
      </div>
      Texto
      <div>
        <TextField
          type={'text'}
          label={'Digite o texto'}
          variant={'filled'}
          defaultValue={text}
          onChange={value => setText(value.target.value)}
          fullWidth
          rows={4}
        />
      </div>
      <div className="mph-custom-text-wrapper">
        <p className="mph-title">
          Escolha a fonte do conteúdo do texto para o template.
        </p>
        <Select
          className="mph-select"
          defaultValue={defaultTextFontFamily}
          label="Fonte"
          variant="outlined"
          options={fontFamilyOptions}
          onChange={el => setTextFontFamily(el.target.value)}
        />
        <Select
          className="mph-select"
          defaultValue={defaultTextFontSize}
          label="Tamanho"
          variant="outlined"
          options={textFontSizeOptions}
          onChange={el => setTextFontSize(el.target.value)}
        />
      </div>
    </div>
  );
};
