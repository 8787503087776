import React, { useContext, useEffect, useState } from 'react';

import './app.scss';

import Logo from 'svg-react-loader!assets/images/logo_compacta_negativa.svg';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect } from 'react-redux';
import {
  BaseApp,
  UserMenu,
  SnackbarProvider,
  SnackbarServiceInstance
} from 'morpheus-base-react';
import { ThemeOptionsType } from 'morpheus-base-react/dist/components/Theme/ThemeTypes';

import { AuthContext } from './auth-context';
import { menuItems } from './menuItems';
import Routes from './routes';
import { Login } from './login/login';

import { State, Dispatch } from 'app/store/type';
import { getUser, selectUser, errorUser } from 'app/store/user';
import { UsuarioService } from '../service/usuario-service';

const mapState = (state: State) => ({
  user: selectUser(state),
  error: errorUser(state)
});
const mapDispatch = (dispatch: Dispatch) => ({
  getUser: () => dispatch(getUser())
});

const themeOptions: ThemeOptionsType = {
  palette: {
    primary: {
      light: '#506398',
      main: '#004de8',
      dark: '#00143f',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#6679ff',
      main: '#20396a',
      dark: '#0026b4',
      contrastText: '#FFFFFF'
    }
  }
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps;

// interface AppState {}

const firebase = window.firebase;
const firebaseui = window.firebaseui;

const firebaseuiConfig = {
  signInSuccessUrl: process.env.LOGIN_SUCCESS_REDIRECT_URL,
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  },
  signInFlow: 'popup'
};

const AppComp: React.FC = ({ error }: Props) => {
  const { user, setUser } = useContext(AuthContext);
  const [ui, setUi] = useState<firebaseui.auth.AuthUI>();
  const [email, setEmail] = useState('');

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        setUser(undefined);
      } else {
        user.getIdToken().then(token => {
          localStorage.setItem('gump-app-tkn', token);
        });
      }

      if (user && user.emailVerified === true) {
        setUser(user);
      }

      if (user && user.emailVerified === false) {
        /* const actionCodeSettings = {
          url: 'http://localhost:4000/'
        }; */
        //user.sendEmailVerification(actionCodeSettings);
        const userService = new UsuarioService();
        userService.confirm(user.email).then(() => {
          //muda div tela
          setEmail(user.email);
        });
      }
    });

    setUi(new firebaseui.auth.AuthUI(firebase.auth()));
  }, [setUser]);

  useEffect(() => {
    if (!user && ui) {
      ui.start('#firebaseui-auth-container', firebaseuiConfig);
    }
  }, [user, ui]);

  const onLogout = () => {
    //localStorage.removeItem('gump-app-tkn');
    localStorage.clear();
    firebase.auth().signOut();
    setUser(undefined);
  };
  document.addEventListener('logout', () => {
    onLogout();
  });

  const menu = () => {
    return (
      <>
        {/* <Notification /> */}
        <UserMenu
          items={[
            {
              title: 'Minha Conta',
              icon: 'User',
              onClick: () => (window.location.href = '/usuario')
            },
            {
              title: 'Sair',
              icon: 'Exit',
              onClick: () => onLogout()
            }
          ]}
        />
      </>
    );
  };

  useEffect(() => {
    error &&
      SnackbarServiceInstance.showMessage({
        message: 'Erro ao buscar informações do usuário.',
        variant: 'error'
      });
  }, [error]);

  return (
    <>
      {!user && <Login email={email} />}
      {user && (
        <BaseApp
          themeObject={themeOptions}
          logo={<Logo />}
          /* headerMiddle={this.account()} */
          headerRight={menu()}
          routes={
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
              <Routes user={firebase.default.auth().currentUser} />
            </MuiPickersUtilsProvider>
          }
          menuItems={menuItems}
          margin="26px"
        />
      )}
      <SnackbarProvider />
    </>
  );
};

export const App = connect<StateProps, DispatchProps, AppProps, State>(
  mapState,
  mapDispatch
)(AppComp);
