import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { createCampaign, updateCampaign } from 'app/store/campaign/action';
import {
  TextField,
  SnackbarServiceInstance,
  MessageAlert
} from 'morpheus-base-react';
import { CampaignCard } from './CampaignCard';
import { styled } from '@material-ui/styles';
import { NavigationButtons } from '../NavigationButtons';
import PaginaInteira from 'svg-react-loader!assets/images/pagina-inteira.svg';
import Template from 'svg-react-loader!assets/images/template.svg';

const Wrapper = styled('div')({
  height: 'calc(100% - 66px)',
  overflow: 'auto'
});

const MainTitle = styled('h1')({
  fontSize: '24px',
  color: '#62708b',
  fontWeight: 'bold',
  margin: '16px',
  fontFamily: 'Source Sans Pro'
});

const CardWrapper = styled('div')({
  display: 'flex',
  fontFamily: 'Source Sans Pro'
});

const FormWrapper = styled('div')({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Source Sans Pro'
});

const Title = styled('h2')({
  fontSize: '16px',
  color: '#62708b',
  fontWeight: 600,
  margin: '16px',
  fontFamily: 'Source Sans Pro'
});

const CampaignName = styled(TextField)({
  color: '#62708b',
  fontSize: '16px',
  fontFamily: 'Source Sans Pro'
});

const CampaignDescription = styled(TextField)({
  color: '#62708b',
  fontSize: '16px',
  fontFamily: 'Source Sans Pro'
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
});

const Footer = styled('footer')({
  height: '66px',
  width: '100%'
});

export const SelectionStep = ({ forwardAction, backwardsAction }) => {
  const campaignData = useSelector((state: any) => state.campaign.campaign);
  const dispatch = useDispatch();

  const campaignID = _get(campaignData, 'campanha_id', 0);
  const fetchCampaignName = _get(campaignData, 'nome', '');
  const fetchCampaignDescription = _get(campaignData, 'descricao', '');
  const fetchCampaignType = _get(campaignData, 'tipo', 'LAYOUT_PRE_DEFINIDO');

  const [campaignName, setCampaignName] = useState(fetchCampaignName);
  const [campaignDescription, setCampaignDescription] = useState(
    fetchCampaignDescription
  );
  const [campaignType, setCampaignType] = useState(fetchCampaignType);

  useEffect(() => {
    if (campaignData) {
      setCampaignName(fetchCampaignName);
      setCampaignDescription(fetchCampaignDescription);
      setCampaignType(fetchCampaignType);
    }
  }, [
    campaignData,
    fetchCampaignName,
    fetchCampaignDescription,
    fetchCampaignType
  ]);

  const validate = () => {
    if (!campaignName || !campaignName.length) {
      SnackbarServiceInstance.showMessage({
        message: 'O nome do formulário é obrigatório.',
        variant: 'error'
      });
      return false;
    }
    return true;
  };

  const handleForwardAction = async () => {
    if (!validate()) {
      return;
    }
    if (campaignID > 0) {
      await dispatch(
        updateCampaign(campaignID, {
          nome: campaignName,
          descricao: campaignDescription,
          tipo: campaignType
        })
      );
    } else {
      await dispatch(
        createCampaign(campaignName, campaignDescription, campaignType)
      );
    }
    return forwardAction();
  };

  return (
    <>
      <Wrapper>
        <MainTitle>Nome do formulário</MainTitle>
        <MessageAlert
          closeable={false}
          show={true}
          title="Dê um nome para seu formulário."
          msg="O nome do formulário irá te ajudar a identificar e analisar as respostas posteriormente."
        />
        <Content>
          <FormWrapper>
            <Title>Nome e descrição</Title>
            <CampaignName
              placeholder="Nome do formulário"
              variant="outlined"
              value={campaignName}
              onChange={({ target: { value } }) => setCampaignName(value)}
            />
            <CampaignDescription
              placeholder="Descrição..."
              variant="outlined"
              value={campaignDescription}
              onChange={({ target: { value } }) =>
                setCampaignDescription(value)
              }
            />
            <Title>Você prefere seu formulário com:</Title>
          </FormWrapper>
          <CardWrapper>
            <CampaignCard
              onChange={() => setCampaignType('LAYOUT_PRE_DEFINIDO')}
              checked={campaignType === 'LAYOUT_PRE_DEFINIDO'}
              infoLabel="Selecione este item para criar uma página utilizando nosso layout pré definido que já cria a landing page necessária."
              icon={<Template />}
              name="Página Inicial + CHAT"
            />
            <CampaignCard
              onChange={() => setCampaignType('PAGINA_INTEIRA')}
              checked={campaignType === 'PAGINA_INTEIRA'}
              infoLabel="Selecione este item se vc quer adicionar o formulário em algum site já construído."
              icon={<PaginaInteira />}
              name="Apenas CHAT"
            />
            <CampaignCard
              onChange={() => setCampaignType('PERSONALIZADO')}
              checked={campaignType === 'PERSONALIZADO'}
              customOption
            />
          </CardWrapper>
        </Content>
      </Wrapper>
      <Footer>
        <NavigationButtons
          forwardAction={() => handleForwardAction()}
          backwardsAction={() => backwardsAction()}
        />
      </Footer>
    </>
  );
};
