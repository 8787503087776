import { AxiosResponse } from 'axios';
import { HttpClient } from './http-client';

export interface Question {
  id: number;
  texto: string;
  tipo: string;
  variavel: string | null | undefined;
  campanha_id: string;
  mensagem_inicial: string;
  proxima_pergunta_id: number | null | undefined;
  final_fluxo: boolean;
  mascara: string | null | undefined;
  opcoes?: Option[] | any[];
}

export interface Option {
  id: number;
  chave: string;
  valor: string;
  campanha_pergunta_id: number;
  proxima_pergunta_id: number | null | undefined;
  classe_css: string | null | undefined;
  ordem: number;
}

interface payloadQuestion {
  campanha_id: number;
  texto: string | ArrayBuffer | null;
  formatting_settings: string | null;
  tipo: string;
  proxima_pergunta_id: number | null;
  final_fluxo: boolean;
  mensagem_inicial: boolean;
  variavel: null;
  mascara: null;
  variavel_integracao: null;
  id?: number | null;
}

interface payloadQuestionOptions {
  campanha_pergunta_id?: number | null;
  chave?: string | null;
  classe_css?: string | null;
  ordem?: number | null;
  valor?: string | null;
  id?: number | null;
}
//TODO: Corrigir chamada de serviços passando um payload
export class CampaignQuestionService {
  private url = 'campanhas/perguntas';

  public async createQuestion(
    question: payloadQuestion
  ): Promise<AxiosResponse<Question>> {
    return HttpClient.post(`${this.url}/`, question);
  }

  public async createOption(
    options: payloadQuestionOptions
  ): Promise<AxiosResponse<Option>> {
    return HttpClient.post(`${this.url}/options`, options);
  }

  public async updateQuestions(
    questions: payloadQuestion[],
    campaign_id: number
  ) {
    return HttpClient.put(`${this.url}/update`, {
      questionario: questions,
      campanha_id: campaign_id
    });
  }

  public async filterQuestions(
    questions: payloadQuestion[]
  ): Promise<AxiosResponse<Question[]>> {
    return HttpClient.post(`${this.url}/filter`, questions);
  }
}
