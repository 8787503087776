import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, BasePage, Button } from 'morpheus-base-react';
import { getUser, updateUser, deleteUser } from 'app/store/user';
import { TextField } from 'morpheus-base-react';
import { Modal } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import './usuario.scss';
import TrashBig from 'svg-react-loader!assets/images/trash-alt.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      padding: 24,
      outline: 'none',
      borderRadius: '8px',
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.16)'
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cancelButton: {
      marginRight: '12px',
      color: '#62708b'
    },
    removeButton: {
      color: '#ffffff',
      borderColor: '#ff003a',
      backgroundColor: '#ff003a'
    }
  })
);

export const Usuario = ({ usr }) => {
  const [openModal, setOpenModal] = useState(false);
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState({
    name: false,
    email: false,
    password: false
  });
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      user.picture && setPicture(user.picture);
      user.name && setName(user.name);
      user.email && setEmail(user.email);
    }
  }, [user]);

  const chooseFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.jpg,.jpeg,.png';
    input.onchange = (e: any) => {
      if (e != null) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setPicture(reader.result);
        reader.onerror = () => {
          console.error(reader.error);
          alert('Erro ao inserir arquivo, tente novamente');
        };
      }
    };
    input.click();
  };

  const onSave = () => {
    if (
      (newPassword && (!confirmPassword || newPassword !== confirmPassword)) ||
      (confirmPassword && !newPassword)
    ) {
      setError({ ...error, password: true });
      return;
    }

    if (!name) {
      setError({ ...error, name: true });
      return;
    }

    if (!email) {
      setError({ ...error, email: true });
      return;
    }

    const payload = { ...user, name, email };
    if (user.picture !== picture) payload.picture = picture;
    if (newPassword && newPassword === confirmPassword) {
      usr.updatePassword(newPassword);
    }

    dispatch(updateUser(payload));
  };

  const deleteAccount = () => {
    dispatch(deleteUser());
  };

  return (
    <BasePage>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '12px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '12px'
          }}
        >
          <Avatar
            size="64px"
            src={picture ? picture : 'assets/images/user.svg'}
          >
            Avatar
          </Avatar>
          <Button
            style={{ marginTop: '3px' }}
            size="small"
            variant="text"
            onClick={chooseFile}
          >
            Alterar
          </Button>
        </div>
        <TextField
          style={{ width: '50%', margin: '12px', backgroundColor: '#ffffff' }}
          label="Nome"
          variant="outlined"
          value={name || ''}
          onChange={({ target: { value } }) => {
            setError({ ...error, name: false });
            setName(value);
          }}
        />
        <p
          hidden={!error.name}
          style={{
            color: 'red',
            width: '50%',
            marginTop: '-12px',
            fontSize: '12px'
          }}
        >
          Campo obrigatório!
        </p>
        {user &&
          user.firebase &&
          user.firebase.sign_in_provider &&
          user.firebase.sign_in_provider !== 'google.com' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <TextField
                style={{ width: '50%', margin: '12px' }}
                label="E-mail"
                variant="outlined"
                value={email || ''}
                onChange={({ target: { value } }) => {
                  setError({ ...error, email: false });
                  setEmail(value);
                }}
              />
              <p
                hidden={!error.email}
                style={{
                  color: 'red',
                  width: '50%',
                  marginTop: '-12px',
                  fontSize: '12px'
                }}
              >
                Campo obrigatório!
              </p>

              <TextField
                style={{ width: '50%', margin: '12px' }}
                label="Nova senha"
                variant="outlined"
                type="password"
                value={newPassword || ''}
                onChange={({ target: { value } }) => setNewPassword(value)}
              />
              <TextField
                style={{ width: '50%', margin: '12px' }}
                label="Confirmar nova senha"
                variant="outlined"
                type="password"
                value={confirmPassword || ''}
                onChange={({ target: { value } }) => {
                  setError({ ...error, password: false });
                  setConfirmPassword(value);
                }}
              />
              <p
                hidden={!error.password}
                style={{
                  color: 'red',
                  width: '50%',
                  marginTop: '-12px',
                  fontSize: '12px'
                }}
              >
                Campo inválido! A confirmação de senha deve ser igual a nova
                senha.
              </p>
            </div>
          )}

        <div className="OptionsUser">
          <Button size="small" className="buttonSave" onClick={onSave}>
            Salvar
          </Button>

          <Button
            size="small"
            className="buttonDelete"
            onClick={() => setOpenModal(true)}
          >
            Excluir minha conta
          </Button>
        </div>

        <Modal
          className={classes.modal}
          open={openModal}
          /* onClose={() => deleteAccount()} */
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <TrashBig />
            <h2
              style={{
                color: '#20396a',
                fontSize: '18px',
                font: 'Source Sans Pro',
                fontWeight: 'bold'
              }}
            >
              Deseja mesmo excluir a conta?
            </h2>
            <p
              style={{
                color: '#62708b',
                fontSize: '16px',
                font: 'Source Sans Pro'
              }}
            >
              Esse processo será irreversível. Caso queira continuar, clique no
              botão “Excluir”
            </p>
            <div style={{ display: 'flex', float: 'right', marginTop: '24px' }}>
              <Button
                className={classes.cancelButton}
                size="small"
                variant="text"
                onClick={() => setOpenModal(false)}
              >
                <span>Cancelar</span>
              </Button>
              <Button
                className={classes.removeButton}
                variant="outlined"
                size="small"
                color="inverse"
                onClick={() => {
                  deleteAccount();
                  setOpenModal(false);
                }}
              >
                <span>Excluir</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </BasePage>
  );
};
