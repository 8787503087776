import React, { useState, useEffect, useCallback } from 'react';
import _get from 'lodash/get';

import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@material-ui/styles';

import { Collapse, Grid, IconButton, Tooltip } from 'morpheus-base-react';
import '../../novo-formulario.scss';

import { updateCampaign } from 'app/store/campaign/action';
import { CustomImageBackground } from './CustomImageBackground';
import { CustomMark } from './CustomMark';
import { CustomButton } from './CustomButton';
import { CustomText } from './CustomText';
import { CustomColor } from './CustomColor';
import { TemplateContent } from './TemplateContent';
import { NavigationButtons } from '../NavigationButtons';
import { isNaN } from 'app/utils/is-nan';

import './template.scss';

interface TemplateStepProps {
  forwardAction: () => void;
  backwardsAction: () => void;
}

const LeftSection = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f2f3f9'
});

const RightSection = styled(Grid)({
  display: 'flex',
  flexDirection: 'column'
});

const Title = styled('h1')({
  alignItems: 'center',
  backgroundColor: 'white',
  color: '#62708b',
  display: 'flex',
  fontSize: '24px',
  fontWeight: 600,
  justifyContent: 'space-between',
  margin: 0,
  fontFamily: 'Source Sans Pro',
  padding: '16px',
  '& path': {
    fill: '#62708b !important'
  }
});

function updateChatProperty(props: Record<string, unknown>) {
  document.dispatchEvent(
    new CustomEvent('update-by-session', {
      bubbles: true,
      composed: true,
      detail: props
    })
  );
}

export const TemplateStep = ({
  forwardAction,
  backwardsAction
}: TemplateStepProps) => {
  const campaignData = useSelector((state: any) => state.campaign.campaign);
  const dispatch = useDispatch();

  const campaignID = _get(campaignData, 'campanha_id', 0);
  const fetchBackgroundImg = _get(
    campaignData,
    'template.backgroundImg',
    `${process.env.URL_BUCKET}/home_office.jpg`
  );
  const fetchLogo = _get(
    campaignData,
    'template.logo',
    `${process.env.URL_BUCKET}/logo-menu-mobile.svg`
  );
  let fetchLogoHeight = Number(_get(campaignData, 'template.logoHeight', 48));
  if (isNaN(fetchLogoHeight)) {
    fetchLogoHeight = 48;
  }

  const fetchColorPattern = _get(
    campaignData,
    'template.colorPattern',
    '#004de8'
  );
  let fetchColorTransp = Number(_get(campaignData, 'template.colorTransp', 0));
  if (isNaN(fetchColorTransp)) {
    fetchColorTransp = 0;
  }

  const fetchButton = _get(campaignData, 'template.button', 'quadrado');
  const fetchTextButton = _get(
    campaignData,
    'template.textButton',
    'LOREM IPSUM'
  );
  const fetchTextContent = _get(
    campaignData,
    'template.textContent',
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.'
  );
  const fetchTitleContent = _get(
    campaignData,
    'template.titleText',
    'Lorem ipsum'
  );
  const fetchTitleContentFontFamily = _get(
    campaignData,
    'template.titleContentFontFamily',
    'open Sans'
  );
  const fetchTitleContentFontSize = _get(
    campaignData,
    'template.titleContentFontSize',
    40
  );
  const fetchTextContentFontFamily = _get(
    campaignData,
    'template.textContentFontFamily',
    'open Sans'
  );
  const fetchTextContentFontSize = _get(
    campaignData,
    'template.textContentFontSize',
    32
  );

  const [backgroundImg, setBackgroundImg] = useState(fetchBackgroundImg);
  const [logo, setLogo] = useState(fetchLogo);
  const [colorPattern, setColorPattern] = useState(fetchColorPattern);
  const [colorTransp, setColorTransp] = useState(fetchColorTransp);
  const [logoHeight, setLogoHeight] = useState(fetchLogoHeight);
  const [button, setButton] = useState(fetchButton);
  const [textButton, setTextButton] = useState(fetchTextButton);
  const [textContent, setTextContent] = useState(fetchTextContent);
  const [titleText, setTitleText] = useState(fetchTitleContent);
  const [titleContentFontFamily, setTitleContentFontFamily] = useState(
    fetchTitleContentFontFamily
  );
  const [textContentFontFamily, setTextContentFontFamily] = useState(
    fetchTextContentFontFamily
  );
  const [titleContentFontSize, setTitleContentFontSize] = useState(
    fetchTitleContentFontSize
  );
  const [textContentFontSize, setTextContentFontSize] = useState(
    fetchTextContentFontSize
  );

  function removeFile(type) {
    if (type === 'background')
      setBackgroundImg(`${process.env.URL_BUCKET}/home_office.jpg`);

    if (type === 'logo')
      setLogo(`${process.env.URL_BUCKET}/logo-menu-mobile.svg`);
  }

  function chooseFile(setComponenteState) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.gif,.jpg,.jpeg,.png,.doc,.docx';
    input.setAttribute('data-max-size', '10240');
    input.onchange = (e: any) => {
      if (e != null) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setComponenteState(reader.result);
          updateChatProperty({
            chatIconClosed: reader.result,
            iconHeader: reader.result,
            chatIconMessage: reader.result
          });
        };
        reader.onerror = () => {
          console.error(reader.error);
          alert('Erro ao inserir arquivo, tente novamente');
        };
      }
    };
    input.click();
  }

  const saveData = useCallback(
    (
      colorPattern,
      colorTransp,
      logoHeight,
      button,
      textButton,
      textContent,
      titleText,
      titleContentFontFamily,
      textContentFontFamily,
      titleContentFontSize,
      textContentFontSize
    ) => {
      dispatch(
        updateCampaign(campaignID, {
          template: {
            colorPattern,
            colorTransp,
            logoHeight,
            button,
            textButton,
            textContent,
            titleText,
            titleContentFontFamily,
            textContentFontFamily,
            titleContentFontSize,
            textContentFontSize
          }
        })
      );
    },
    [campaignID, dispatch]
  );

  useEffect(
    () =>
      saveData(
        colorPattern,
        colorTransp,
        logoHeight,
        button,
        textButton,
        textContent,
        titleText,
        titleContentFontFamily,
        textContentFontFamily,
        titleContentFontSize,
        textContentFontSize
      ),
    [
      colorPattern,
      colorTransp,
      logoHeight,
      button,
      textButton,
      textContent,
      titleText,
      titleContentFontFamily,
      textContentFontFamily,
      titleContentFontSize,
      textContentFontSize,
      saveData
    ]
  );

  const saveImages = useCallback(
    (backgroundImg, logo) => {
      dispatch(
        updateCampaign(campaignID, {
          template: {
            backgroundImg,
            logo
          }
        })
      );
    },
    [campaignID, dispatch]
  );

  useEffect(() => saveImages(backgroundImg, logo), [
    backgroundImg,
    logo,
    saveImages
  ]);

  return (
    <>
      <Grid container className="mph-wrapper">
        <LeftSection item xs={3}>
          <Title>
            Template
            <Tooltip
              placement="bottom"
              title={
                <p className="tooltip">
                  Aqui você configura sua página inicial, onde o cliente irá
                  interagir a princípio. É uma landing page simplea para criar
                  engajamento."
                </p>
              }
            >
              <div>
                <IconButton icon="InfoCircle" />
              </div>
            </Tooltip>
          </Title>
          <Collapse
            colorTitle="#20396a"
            color="#f2f3f9"
            expandedMultiples={false}
            collapseProps={[
              {
                title: 'IMAGEM DE FUNDO',
                disabled: false,
                content: (
                  <CustomImageBackground
                    avatarImg={backgroundImg}
                    onClick={() => chooseFile(setBackgroundImg)}
                    onRemove={() => removeFile('background')}
                  />
                )
              },
              {
                title: 'SUA MARCA',
                disabled: false,
                content: (
                  <CustomMark
                    avatarImg={logo}
                    onClick={() => chooseFile(setLogo)}
                    onRemove={() => removeFile('logo')}
                    OnChangeHeight={setLogoHeight}
                    heig={logoHeight}
                  />
                )
              },
              {
                title: 'PADRÃO DE COR',
                disabled: false,
                content: (
                  <CustomColor
                    onChangeColor={setColorPattern}
                    onChangeTransp={setColorTransp}
                    color={colorPattern}
                    transp={colorTransp}
                  />
                )
              },
              {
                title: 'BOTÃO DE AÇÃO',
                disabled: false,
                content: (
                  <CustomButton
                    button={button}
                    textButton={textButton}
                    setButton={setButton}
                    setTextButton={setTextButton}
                  />
                )
              },
              {
                title: 'TEXTOS',
                disabled: false,
                content: (
                  <CustomText
                    setTitle={setTitleText}
                    setText={setTextContent}
                    title={titleText}
                    text={textContent}
                    setTextFontFamily={setTextContentFontFamily}
                    setTextFontSize={setTextContentFontSize}
                    setTitleFontFamily={setTitleContentFontFamily}
                    setTitleFontSize={setTitleContentFontSize}
                    defaultTextFontFamily={textContentFontFamily}
                    defaultTextFontSize={textContentFontSize}
                    defaultTitleFontFamily={titleContentFontFamily}
                    defaultTitleFontSize={titleContentFontSize}
                  />
                )
              }
            ]}
          />
        </LeftSection>
        <RightSection item xs={9}>
          <TemplateContent
            img={backgroundImg}
            logo={logo}
            title={titleText}
            text={textContent}
            color={colorPattern}
            transp={colorTransp}
            heig={logoHeight}
            button={button}
            textButton={textButton}
            titleFontFamily={titleContentFontFamily}
            titleFontSize={titleContentFontSize}
            textFontFamily={textContentFontFamily}
            textFontSize={textContentFontSize}
          />
        </RightSection>
      </Grid>
      <footer className="mph-step-footer">
        <NavigationButtons
          forwardAction={() => forwardAction()}
          backwardsAction={() => backwardsAction()}
        />
      </footer>
    </>
  );
};
