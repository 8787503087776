import React from 'react';
import { fetchIcon } from './fetchIcon';
import { styled } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import PlusAdd from 'svg-react-loader!assets/images/plus-add.svg';
import './conversation-step.scss';
import { ConversationCardItem } from './ConversationList';

interface FlowObject {
  order: string | number;
  name: string;
}

export interface ConversationCardProps {
  cardList: ConversationCardItem[];
  firstCard: boolean;
  order: string | number;
  nextQuestion: number | null;
  icon: string;
  title: string;
  content: React.ReactNode;
  flow?: FlowObject[];
  onClickDelete: () => void;
  onClickConfig: () => void;

  type: string;
  options: any;
  setOptionID: (number) => void;
  openSidebar: (number) => void;
}

// eslint-disable-next-line complexity
const setBackgroundColorByOrder = (order: number) => {
  switch (order) {
    case 1:
      return '#004de8';
    case 2:
      return '#ff003a';
    case 3:
      return '#2ecc71';
    case 4:
      return '#ff9800';
    case 5:
      return '#9c27b0';
    default:
      return '#607d8b';
  }
};

export const ConversationCard = ({
  cardList,
  firstCard,
  order,
  nextQuestion,
  icon,
  title,
  onClickDelete,
  onClickConfig,
  type,
  options,
  setOptionID,
  openSidebar
}: ConversationCardProps) => {
  const campaignData = useSelector((state: any) => state.campaign.campaign);
  let format;
  let text;
  const questionario = campaignData.questionario;
  if (questionario.length >= parseInt(order) + 1) {
    format = JSON.parse(questionario[order].formatting_settings);
    text = questionario[order].texto;
  }

  const Wrapper = styled('div')({
    marginLeft: firstCard ? '0' : '15px',
    /* backgroundColor: `${setBackgroundColorByOrder(Number(order))}08`, */
    backgroundColor: `${'#004de8'}08`,
    overflow: 'hidden',
    fontFamily: 'Source Sans Pro',
    borderRadius: '8px',
    display: 'flex',
    border: '1px solid transparent',
    transition: 'all 0.4s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      /* border: `1px solid ${setBackgroundColorByOrder(Number(order))}` */
      border: `1px solid ${'#004de8'}`
    }
  });

  const OrderDisplay = styled('div')({
    /* backgroundColor: setBackgroundColorByOrder(Number(order)), */
    backgroundColor: '#004de8',
    fontWeight: 600,
    minHeight: '100%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '24px'
    // width: '24px'
  });

  const RightSection = styled('div')({
    width: '100%',
    wordWrap: 'break-word',
    padding: '8px 4px 8px 16px'
  });

  const MenuWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  });

  const Title = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      fontWeight: 600,
      fontSize: '12px',
      color: '#20396a',
      margin: 0,
      marginLeft: '4px'
    }
  });

  const MenuButtonWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center'
  });

  const MenuButton = styled('button')({
    display: 'flex',
    minWidth: '24px',
    minHeight: '24px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 0,
    color: '#62708b',
    height: '16px',
    width: '16px',
    transition: 'color 0.4s ease-in-out',
    '&:hover': {
      color: setBackgroundColorByOrder(Number(order))
    },
    padding: 0
  });

  const AddButton = styled('button')({
    margin: '4px 15px',
    borderRadius: '100%',
    height: '24px',
    width: '24px',
    backgroundColor: '#20396a',
    cursor: 'pointer',
    border: 0,
    padding: 0,
    overflow: 'hidden',
    '& svg': {
      height: '13px',
      width: '14px'
    }
  });

  const styleTxt = (text, formattingSettings) => {
    // eslint-disable-next-line consistent-return
    const applyStyle = (txt, style) => {
      if (style === 'ITALIC') {
        return txt.italics();
      }
      if (style === 'BOLD') {
        return txt.bold();
      }
      if (style === 'UNDERLINE') {
        return `<u>${txt}</u>`;
      }
    };

    // eslint-disable-next-line no-shadow
    const recort = (text, offset, len) => {
      return text.substr(offset, len);
    };

    if (!formattingSettings || !formattingSettings.blocks.length) {
      return text;
    }

    const final = formattingSettings.blocks.map(block => {
      if (!block.inlineStyleRanges.length) {
        return block.text;
      }

      // ordenando por offset
      const { inlineStyleRanges } = block;
      let swapped;
      do {
        swapped = false;
        // eslint-disable-next-line max-depth
        for (let i = 0; i < inlineStyleRanges.length - 1; i += 1) {
          // eslint-disable-next-line max-depth
          if (inlineStyleRanges[i].offset > inlineStyleRanges[i + 1].offset) {
            const temp = inlineStyleRanges[i];
            inlineStyleRanges[i] = inlineStyleRanges[i + 1];
            inlineStyleRanges[i + 1] = temp;
            swapped = true;
          }
        }
      } while (swapped);

      let newTextLine = block.text;
      for (let index = 0; index < inlineStyleRanges.length; index += 1) {
        const inlineStyleRange = inlineStyleRanges[index];
        const { offset } = inlineStyleRange;
        const len = inlineStyleRange.length;
        const txt = recort(newTextLine, offset, len);
        newTextLine =
          newTextLine.slice(0, offset) +
          applyStyle(txt, inlineStyleRange.style) +
          newTextLine.slice(offset + len);
        // eslint-disable-next-line max-depth
        for (let i = index + 1; i < inlineStyleRanges.length; i += 1) {
          // eslint-disable-next-line max-depth
          if (
            (inlineStyleRanges[i].offset > offset &&
              inlineStyleRanges[i].offset < offset + len) ||
            inlineStyleRanges[i].offset === offset
          ) {
            // eslint-disable-next-line operator-assignment
            inlineStyleRanges[i].offset = inlineStyleRanges[i].offset + 3;
          } else {
            // eslint-disable-next-line operator-assignment
            inlineStyleRanges[i].offset = inlineStyleRanges[i].offset + 7;
          }
        }
      }
      return newTextLine;
    });
    return ReactHtmlParser(final.join('<br>'));
  };

  function ListItem(props) {
    const num =
      props.nextQuestionOption === -1
        ? props.nextQuestion
        : props.nextQuestionOption;
    return (
      <Wrapper className="options">
        {type != 'checkbox' ? (
          <div className="wrapperOptions">
            <div className="containerOptions">
              <div className="valueOption">{props.value}</div>
              <div className="addOptions">
                <AddButton
                  onClick={() => {
                    setOptionID(props.index + 1);
                    openSidebar(order);
                  }}
                >
                  <PlusAdd />
                </AddButton>
              </div>
            </div>
            {num !== -1 && <div className="nextQuestion">{num}</div>}
          </div>
        ) : (
          <div className="containerOptions">
            <div className="valueOption">{props.value}</div>
          </div>
        )}
      </Wrapper>
    );
  }

  function OptionList(props) {
    const options = props.options;
    const listItems = options.map((option, index) => {
      const nextQuestionOption = cardList.findIndex(
        item => item.id === option.proxima_pergunta_id
      );
      return (
        <ListItem
          key={index.toString()}
          value={option.valor}
          nextQuestionOption={nextQuestionOption}
          nextQuestion={nextQuestion}
          index={index}
        />
      );
    });
    return <ul>{listItems}</ul>;
  }

  return (
    <Wrapper>
      {!firstCard && <OrderDisplay>{order}</OrderDisplay>}
      <RightSection>
        <MenuWrapper>
          <Title>
            {fetchIcon(icon)}
            <h2>{title}</h2>
          </Title>
          <MenuButtonWrapper>
            {!firstCard && (
              <MenuButton title="Deletar" onClick={onClickDelete}>
                {fetchIcon('trash')}
              </MenuButton>
            )}
            <MenuButton title="Configurações" onClick={onClickConfig}>
              {fetchIcon('edit')}
            </MenuButton>
          </MenuButtonWrapper>
        </MenuWrapper>
        <div>{styleTxt(text, format)}</div>
        {options && options[0] != null ? (
          <OptionList options={options} />
        ) : null}
      </RightSection>
      {nextQuestion &&
      nextQuestion !== -1 &&
      (!options || options[0] === null) ? (
        <OrderDisplay>{nextQuestion}</OrderDisplay>
      ) : (
        <div></div>
      )}
    </Wrapper>
  );
};
