import React from 'react';
import * as ReactDOM from 'react-dom';
import './styles/index.scss';
import { StoreProvider } from './app/store';
import { AuthProvider } from 'containers/auth-context';

import * as moment from 'moment';

import { Logger } from 'morpheus-base-react';
import { App } from 'containers/app';

moment.locale('pt-br');

Logger.setFromLocalStorageOrUse('info');

ReactDOM.render(
  <StoreProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </StoreProvider>,
  document.getElementById('root')
);
