import React, { Component } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import './pesquisar-campanha.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  EmptyState,
  BasePage,
  IconButton,
  Icon,
  TextField,
  Table,
  TableHeader,
  SnackbarServiceInstance
} from 'morpheus-base-react';
import { CampanhaStatus } from 'components/campanha-status/campanha-status';
import { CampanhaService, Campanha } from 'service/campanha-service';
import LoadingComponent from 'app/components/loading/loading';
import { styled } from '@material-ui/styles';
import { Modal } from '@material-ui/core';
import TrashBig from 'svg-react-loader!assets/images/trash-alt.svg';

type Props = RouteComponentProps<any>;

interface State {
  openModal: boolean;
  campanhas: Campanha[];
  params: {
    count: number;
    page: number;
    rowsPerPage: number;
    totalPages: number;
  };
  campanha: string;
  filterIsOpen: boolean;
  headers: TableHeader[];
  loading: boolean;
}

const AccessLink = styled('a')({
  fontSize: '16px',
  lineHeight: '1.25',
  color: '#004de8'
});

class PesquisarCampanha extends Component<Props, State> {
  private service = new CampanhaService();

  state: State = {
    openModal: false,
    loading: true,
    campanhas: [],
    params: {
      count: 0,
      page: 1,
      rowsPerPage: 10,
      totalPages: 1
    },
    campanha: '',
    filterIsOpen: false,
    headers: [
      {
        id: '0',
        labelHeader: 'Nome',
        typeCell: 'string',
        attr: 'nome'
      },
      {
        id: '1',
        labelHeader: 'Descrição',
        typeCell: 'string',
        noOrderBy: true,
        attr: 'descricao'
      },
      {
        id: '2',
        labelHeader: 'Data de criação',
        typeCell: 'string',
        noOrderBy: true,
        attr: 'dt_criacao',
        format: value => {
          return moment(value).format('DD/MM/YYYY hh:mm');
        }
      },
      {
        id: '3',
        labelHeader: 'Status',
        typeCell: 'string',
        noOrderBy: true,
        attr: 'status',
        format: value => <CampanhaStatus status={value} />
      },
      {
        id: '4',
        labelHeader: 'URL',
        typeCell: 'string',
        noOrderBy: true,
        attr: 'tag_url',
        format: value => {
          const url = process.env.BASE_URL_CLIENT + value;
          return (
            <>
              <AccessLink href={url} target="_blank">
                {url}
              </AccessLink>
            </>
          );
        }
      },
      {
        id: '5',
        labelHeader: 'Ações',
        typeCell: 'action',
        noOrderBy: true,
        actions: [
          {
            actionType: 'button',
            icone: 'DashboardPartial',
            iconeClass: 'fill-grey-blue',
            alt: 'Visualizar',
            action: item => this.visualizarCampanha(item)
          },
          {
            actionType: 'button',
            icone: 'Edit',
            iconeClass: 'fill-grey-blue',
            alt: 'Editar',
            action: item => this.editarCampanha(item)
          },
          {
            actionType: 'button',
            icone: 'Trash',
            iconeClass: 'fill-grey-blue',
            alt: 'Excluir',
            action: item => {
              this.campaignRemoved = item;
              this.setState({ openModal: true });
            }
          }
        ]
      }
    ]
  };

  campaignRemoved: any = undefined;

  /* classes = this.useStyles(); */

  componentDidMount() {
    this.getCampanhasClient(1, 10);
  }

  deleteCampanha(campanha: Campanha) {
    this.service.delete(campanha.id).then(() => {
      SnackbarServiceInstance.showMessage({
        message: 'Formulário deletado com sucesso.',
        variant: 'success'
      });
      const newCampanhas = this.state.campanhas.filter(item => {
        return item !== campanha;
      });
      const params = this.state.params;
      params.count = params.count - 1;
      this.setState({
        campanhas: newCampanhas,
        params: params
      });
    });
  }

  getCampanhasClient(page: number, size: number) {
    this.service
      .buscarCampanhas(page, size)
      .then(result => result.data)
      .then(result => {
        this.setState({
          campanhas: result.data || [],
          params: result.params,
          loading: false
        });
      });
  }

  updatePagination(pagination) {
    this.setState({ params: pagination, loading: true });
    this.getCampanhasClient(pagination.page, pagination.rowsPerPage);
  }

  getNewCampanha() {
    if (!this.state.campanhas.length) {
      return null;
    }

    return (
      <>
        <div style={{ width: '100%' }}>
          <Button
            className="newForm"
            icon="PlusAdd"
            onClick={() => this.irParaCriarCampanha()}
          >
            Novo Formulário
          </Button>
        </div>
      </>
    );
  }

  wrapContent(Content: JSX.Element) {
    return (
      <>
        <BasePage margin="26px">{Content}</BasePage>
      </>
    );
  }

  irParaCriarCampanha() {
    this.props.history.push('/novo-formulario');
  }

  editarCampanha(campanha: Campanha) {
    this.props.history.push(`/editar-formulario/${campanha.id}`);
  }

  visualizarCampanha(campanha: Campanha) {
    this.props.history.push(`/visualizar/${campanha.id}`);
  }

  getElementsCampanhas() {
    return this.state.campanhas.map(campanha => {
      const dataDeCriacao = moment(campanha.dt_criacao).format('DD/MM/YYYY');

      return (
        <div key={campanha.id}>
          <div className="mph-list-item">
            <div className="mph-list-item-main-content">
              <div className="mph-list-item-main-content-title">
                {campanha.nome || 'N/A'}
              </div>
              <div className="mph-list-item-main-content-secondary">
                {campanha.tag_url}
              </div>
              <div className="mph-list-item-main-content-secondary">
                {/* {usuario.username} */}
              </div>
            </div>

            <div className="mph-list-item-text">
              <p>
                <b>Criada em</b>
              </p>
              {dataDeCriacao}
            </div>

            <div className="mph-list-item-text">
              <p>
                <b>Criada por</b>
              </p>
              {campanha.criado_por}
            </div>

            <div className="mph-list-item-column">
              <Button
                size="small"
                color="secondary"
                icon="Edit"
                onClick={() => console.log('Edit CLICK')}
              >
                Editar
              </Button>
            </div>

            <div className="mph-list-item-column">
              <Button
                size="small"
                icon="Eye"
                onClick={() => this.visualizarCampanha(campanha)}
              >
                Resultados
              </Button>
            </div>
          </div>
        </div>
      );
    });
  }

  renderFooter() {
    return (
      <div className="footerTable">
        <div className="item">
          <p className="label">Total de formulários:</p>
          <p className="value">{this.state.params.count}</p>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <div style={{ marginTop: '72px' }}>
            <LoadingComponent />
          </div>
        </>
      );
    }

    if (!this.state.campanhas.length) {
      return this.wrapContent(
        <>
          <EmptyState
            css={{
              maxWidth: '360px'
            }}
            title="Nenhum Formulário Cadastrado"
            titleVariant="big"
            subtitle="Você não possui formulários cadastrados, vamos criar um novo formulário?"
            icon="CommentAltLines"
            button={{
              caption: 'Criar formulário',
              icon: 'PlusAdd',
              onClick: () => this.irParaCriarCampanha()
            }}
          />
        </>
      );
    }

    return this.wrapContent(
      <>
        <div className="headerTable">
          <TextField
            label="Pesquisar formulários"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() =>
                    this.setState({ filterIsOpen: !this.state.filterIsOpen })
                  }
                >
                  <Icon viewIcone="Sliders" iconColor="secondary" />
                </InputAdornment>
              )
            }}
            value={this.state.campanha}
            onChange={e => this.setState({ campanha: e.target.value })}
          />
          <IconButton
            backgroundColor="primary"
            icon="Search"
            size={52}
            onClick={() => console.log('pesquisar')}
          />
          {this.getNewCampanha()}
        </div>

        <div className="container-list">
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item xs={12}>
              <Table
                title="FORMULÁRIOS CRIADOS"
                headers={this.state.headers}
                data={this.state.campanhas}
                footer={this.renderFooter()}
                colSpanFooter={3}
                pagination={true}
                colSpanPagination={7}
                paginationConfig={this.state.params}
                rowsPerPageOptions={[10, 20, 30]}
                actionPagination={pagination =>
                  this.updatePagination(pagination)
                }
                alignPagination="right"
              ></Table>
            </Grid>
          </Grid>
        </div>

        <div className="Copyright">
          Gump ©2019, Todos os direitos reservados
          <span style={{ marginLeft: '82%' }}>V.1.1</span>
        </div>
        <div
          style={{
            width: '100%',
            height: '15px',
            margin: '15px 117px 16px 0px'
          }}
        ></div>

        <Modal
          className={'modal'}
          open={this.state.openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={'paper'}>
            <button
              className="buttonExit"
              onClick={() => this.setState({ openModal: false })}
            >
              <svg width="16.002" height="16">
                <title id="title">Exit</title>
                <path
                  fill="#62708b"
                  d="M13.4 12l6.3-6.286a1 1 0 1 0-1.419-1.424L12 10.586 5.709 4.29A1 1 0 0 0 4.29 5.709L10.586 12l-6.3 6.286A1 1 0 1 0 5.709 19.7L12 13.4l6.286 6.3a1 1 0 1 0 1.414-1.419z"
                  transform="translate(-3.994 -3.996)"
                />
              </svg>
            </button>

            <div className="TrashIcon">
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <TrashBig />
              </span>
            </div>

            <h2
              style={{
                color: '#20396a',
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Source Sans Pro',
                marginBottom: '8px'
              }}
            >
              Deseja mesmo excluir o formulário?
            </h2>
            <p
              style={{
                color: '#62708b',
                fontSize: '16px',
                fontFamily: 'Source Sans Pro',
                marginTop: '8px',
                marginBottom: '0px'
              }}
            >
              Após a exclusão, seu formulário ficará armazenado por um mês, até
              ser excluído definitivamente. Caso queira recuperá-lo, entre em
              contato com o suporte antes do final deste prazo.
            </p>
            <div style={{ display: 'flex', float: 'right', marginTop: '24px' }}>
              <Button
                className={'cancelButton'}
                size="small"
                variant="text"
                onClick={() => this.setState({ openModal: false })}
              >
                <span style={{ color: '#62708b' }}>Cancelar</span>
              </Button>
              <Button
                className={'removeButton'}
                variant="outlined"
                size="small"
                color="inverse"
                onClick={() => {
                  this.deleteCampanha(this.campaignRemoved);
                  this.setState({ openModal: false });
                }}
              >
                <span>Excluir</span>
              </Button>
            </div>
          </div>
        </Modal>

        <Grid container style={{ marginTop: 32 }}>
          <Grid item />
        </Grid>
      </>
    );
  }
}

export default withRouter(PesquisarCampanha);
