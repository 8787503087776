import React from 'react';
import { Tooltip, Button } from 'morpheus-base-react';
import '../../novo-formulario.scss';
import { Radio as RadioBase } from '@material-ui/core';
import InfoCircle from 'svg-react-loader!assets/images/info-circle.svg';
import { styled } from '@material-ui/styles';

interface CampaignCardProps {
  infoLabel?: string;
  icon?: React.ReactNode;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  customOption?: boolean;
  onChange: () => void;
}

const Wrapper = styled('button')({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: '8px',
  backgroundColor: '#ebeefe',
  width: '180px',
  height: '132px',
  borderRadius: '8px',
  margin: '0 8px',
  border: 0,
  '&:focus, &::-moz-focus-inner': {
    outline: 'none !important',
    border: 0
  }
});

const Radio = styled(RadioBase)({
  position: 'absolute',
  top: '8px',
  left: '8px',
  padding: 0
});

const Icon = styled('div')({
  position: 'absolute',
  top: '8px',
  right: '8px'
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
});

const Name = styled('p')({
  margin: 0,
  marginTop: '8px',
  fontFamily: 'Source Sans Pro'
});

const Disclaimer = styled('div')({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1,
  backgroundColor: '#ff9300',
  color: '#FFF',
  fontSize: '14px',
  height: '32px'
});

const CustomWrapper = styled('div')({
  width: '180px',
  height: '132px',
  borderRadius: '8px',
  margin: '0 8px',
  backgroundColor: '#004de8',
  color: '#fff',
  fontSize: '14px',
  textAlign: 'center'
});

const CustomTitle = styled('h2')({
  marginTop: '12px',
  fontWeight: 600,
  margin: 0
});

const CustomDescription = styled('p')({
  fontSize: '12px',
  margin: '8px 0 10px 0'
});

const CustomButton = styled(Button)({
  color: '#004de8',
  backgroundColor: '#FFF',
  padding: '4px 8px',
  borderRadius: '100px',
  '&:hover, &:focus': {
    backgroundColor: '#FFF'
  }
});
export const CampaignCard = ({
  infoLabel,
  icon,
  name,
  checked,
  onChange,
  disabled,
  customOption
}: CampaignCardProps) => {
  if (customOption) {
    return (
      <CustomWrapper>
        <CustomTitle>Personalizado</CustomTitle>
        <CustomDescription>
          Criaremos um template exclusivo para você.
        </CustomDescription>
        <CustomButton onClick={() => onChange()}>Solicitar</CustomButton>
      </CustomWrapper>
    );
  }

  return (
    <Wrapper
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      onClick={() => onChange()}
      disabled={disabled}
    >
      {disabled && <Disclaimer>Em Breve</Disclaimer>}
      <Radio disabled={disabled} checked={checked} onChange={onChange} />
      <Icon>
        <Tooltip
          aria-label={name}
          title={<p className="tooltip">{infoLabel} </p> || ''}
        >
          <div>
            <InfoCircle />
          </div>
        </Tooltip>
      </Icon>
      <Content>
        {icon}
        <Name>{name}</Name>
      </Content>
    </Wrapper>
  );
};
